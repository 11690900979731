import { Routes } from '@angular/router';
import { DashboardLayoutComponent } from '@citylife/dashboard/shell/ui/layout';
import {
  redirectAuthorizedTo,
  notAuthGuard,
  emailVerifiedGuard,
} from './guards';
import { formatTitle } from '@citylife/shared/utils';
import { DashboardRouterUtil } from '@citylife/dashboard/shared/utils';

export const routes: Routes = [
  {
    path: DashboardRouterUtil.Routes.Auth.Root,
    loadChildren: async () =>
      (await import('@citylife/dashboard/auth/features/shell'))
        .DashboardAuthShellModule,
    ...redirectAuthorizedTo(
      `/${DashboardRouterUtil.Routes.Auth.Root}/${DashboardRouterUtil.Routes.Auth.Verify}`,
      `${DashboardRouterUtil.Routes.Home.Root}`
    ),
  },
  {
    path: DashboardRouterUtil.Routes.Root,
    component: DashboardLayoutComponent,
    canActivateChild: [
      notAuthGuard(
        `/${DashboardRouterUtil.Routes.Auth.Root}/${DashboardRouterUtil.Routes.Auth.Login}`
      ),
      emailVerifiedGuard(
        `/${DashboardRouterUtil.Routes.Home.Root}/${DashboardRouterUtil.Routes.Home.VerifyEmail}`,
        `${DashboardRouterUtil.Routes.Home.Root}`
      ),
    ],
    children: [
      {
        path: DashboardRouterUtil.Routes.Home.Root,
        loadChildren: async () =>
          (await import('@citylife/dashboard/home/features/shell'))
            .DashboardHomeModule,
        title: formatTitle('Home', 'Dashboard'),
      },
      {
        path: DashboardRouterUtil.Routes.Tool.Root,
        loadChildren: async () =>
          (await import('@citylife/dashboard/tool/features/shell')).ToolModule,
        title: formatTitle('Management tools', 'Dashboard'),
      },
      {
        path: DashboardRouterUtil.Routes.Reservation.Root,
        loadChildren: async () =>
          (await import('@citylife/dashboard/reservation/features/shell'))
            .DashboardReservationModule,
      },
      {
        path: DashboardRouterUtil.Routes.Business.Root,
        loadChildren: async () =>
          (await import('@citylife/dashboard/business/features/shell'))
            .BusinessModule,
      },
      {
        path: DashboardRouterUtil.Routes.Settings.Root,
        loadChildren: async () =>
          (await import('@citylife/dashboard/settings/features/shell'))
            .SettingsModule,
      },
      {
        path: '**',
        redirectTo: `${DashboardRouterUtil.Routes.Home.Root}`,
      },
    ],
  },
  {
    path: '**',
    redirectTo: `${DashboardRouterUtil.Routes.Home.Root}`,
  },
];
