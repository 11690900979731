import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReservationState } from './reservation.reducer';

export const RESERVATION_FEATURE_KEY = 'reservation';

const selectReservationState = createFeatureSelector<ReservationState>(
  RESERVATION_FEATURE_KEY
);

export const selectReservations = createSelector(
  selectReservationState,
  (state) => state.reservations
);

export const selectReservationDetails = createSelector(
  selectReservationState,
  (state) => state.details
);
