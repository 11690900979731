import { PLATFORM_ID, makeEnvironmentProviders } from '@angular/core';
import {
  BrowserWindowRef,
  WINDOW,
  WindowRef,
  windowFactory,
} from '@citylife/shared/utils';

export const provideAppWindow = () => {
  return makeEnvironmentProviders([
    { provide: WindowRef, useClass: BrowserWindowRef },
    {
      provide: WINDOW,
      useFactory: windowFactory,
      deps: [WindowRef, PLATFORM_ID],
    },
  ]);
};
