@if(errorSignal(); as error){

<form
  [formGroup]="form"
  (ngSubmit)="submit()"
  class="bg-white flex flex-col overflow-y-scroll h-full gap-5 p-5 rounded-lg"
>
  <mat-icon class="ml-auto cursor-pointer" (click)="cancel()">cancel</mat-icon>
  <h2 class="text-2xl font-bold text-center">Add Reservation</h2>

  <div class="flex flex-col gap-4">
    <div class="flex flex-row gap-3">
      <div class="form-group" clInput [error]="error.firstname">
        <div class="form-control-group">
          <div class="input-group">
            <input formControlName="firstName" />
            <label>First name</label>
          </div>
        </div>
      </div>
      <div class="form-group" clInput [error]="error.lastname">
        <div class="form-control-group">
          <div class="input-group">
            <input formControlName="lastName" />
            <label>Last name</label>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="form-group" clInput [error]="error.email">
      <div class="form-control-group">
        <div class="input-group">
          <input formControlName="email" />
          <label>Email Address</label>
        </div>
      </div>
    </div> -->

    <div class="form-group" clInput [error]="error.phoneNumber">
      <div class="form-control-group">
        <span class="form-prefix">+234</span>
        <div class="input-group">
          <input formControlName="phoneNumber" />
          <label>Phone number</label>
        </div>
      </div>
    </div>
    <div class="flex gap-3">
      <div class="form-group" clInput [error]="error.date">
        <div class="form-control-group">
          <div class="input-group">
            <input type="date" formControlName="date" />
            <label>Date</label>
          </div>
        </div>
      </div>
      <div class="form-group" clInput [error]="error.time">
        <div class="form-control-group">
          <div class="input-group">
            <input type="time" step="60" formControlName="time" />
            <label>Time</label>
          </div>
        </div>
      </div>
    </div>

    <cl-multiselect-input
      [options]="groupedServices"
      [group]="true"
      placeholder="Select Services"
      (valueChange)="setServices($event)"
    ></cl-multiselect-input>
  </div>

  <div class="flex flex-col gap-4">
    <button type="submit" class="btn btn-filled">Add reservation</button>
    <button class="btn btn-outlined" (click)="cancel()">
      <p></p>
      <p></p>
      <p></p>
      <p></p>
      Cancel
    </button>
  </div>
</form>
}
