import {
  GenericState,
  OrderObject,
  ReservationDate,
  ReservationStatus,
} from '@citylife/shared/data-access/models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  getReservationDetailsAction,
  getReservationsAction,
  setReservationDetailsAction,
  setReservationErrorAction,
  setReservationsAction,
} from './reservation.action';
export interface ReservationDetails {
  id: number;
  status: ReservationStatus;
  section?: string;
  amount?: number;
  amountPaid?: number;
  customer: string;
  phoneNumber: string;
  occassion?: string;
  dates: ReservationDate[];
  orders: OrderObject[];
  note?: string;
}

export interface IReservationObject {
  id: number;
  name: string;
  guest: number;
  dateTime: string;
  reservationToolId: string;
  status: ReservationStatus;
}

export interface ReservationState {
  details: GenericState<ReservationDetails | undefined>;
  reservations: GenericState<IReservationObject[]>;
}

const initialState: ReservationState = {
  reservations: {
    status: 'loading',
    data: [],
  },
  details: {
    status: 'success',
    data: undefined,
  },
};

const reducers = createReducer(
  initialState,
  on(getReservationsAction, (state, { setLoading }): ReservationState => {
    return {
      ...state,
      reservations: {
        ...state.reservations,
        data: setLoading ? [] : state.reservations.data,
        status: setLoading ? 'loading' : state.reservations.status,
      },
    };
  }),

  on(setReservationsAction, (state, { value }): ReservationState => {
    return {
      ...state,
      reservations: {
        status: 'success',
        data: [...state.reservations.data, ...value],
      },
    };
  }),

  on(getReservationDetailsAction, (state, { setLoading }): ReservationState => {
    return {
      ...state,
      details: {
        ...state.details,
        status: setLoading ? 'loading' : state.details.status,
      },
    };
  }),

  on(setReservationDetailsAction, (state, { value }): ReservationState => {
    return {
      ...state,
      details: {
        status: 'success',
        data: value,
      },
    };
  }),

  on(setReservationErrorAction, (state, { error }): ReservationState => {
    return {
      ...state,
      details: {
        ...state.details,
        status: 'error',
        error,
      },
    };
  })
);

export const reservationReducer = (
  state: ReservationState | undefined,
  action: Action
) => reducers(state, action);
