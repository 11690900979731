import { Injectable } from '@angular/core';
import {
  AccountApiService,
  CategoryApiService,
  CityLifeApiMenuItemResponse,
  ReservationApiService,
  SearchApiService,
} from '@citylife/shared/data-access/citylife-api';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, map, switchMap } from 'rxjs';
import {
  getBusinessAction,
  getCategoriesAction,
  getMenuCategories,
  getReservationCancellationPolicyAction,
  getReservationHoursAction,
  getReservationSectionAction,
  getReservationServicesAction,
  setBusinessAction,
  setCategoriesAction,
  setMenuCategories,
  setReservationCancellationPolicysAction,
  setReservationHoursAction,
  setReservationSectionsAction,
  setReservationServicesAction,
} from './settings.actions';
import { IServiceCategoryObject } from '@citylife/shared/data-access/models';

@Injectable()
export class SettingsEffect {
  constructor(
    private readonly actions$: Actions,
    private readonly searchApi: SearchApiService,
    private readonly categoryApi: CategoryApiService,
    private readonly reservationApi: ReservationApiService
  ) {}

  private searchBusiness$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBusinessAction),
      switchMap(({ value }) =>
        this.searchApi
          .searchBusiness(value)
          .pipe(map(({ data }) => setBusinessAction({ value: data })))
      )
    )
  );

  private getCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCategoriesAction),
      switchMap(() =>
        this.categoryApi
          .getAllCategories()
          .pipe(map(({ data }) => setCategoriesAction({ value: data })))
      )
    )
  );

  private getReservationServices = createEffect(() =>
    this.actions$.pipe(
      ofType(getReservationServicesAction),
      switchMap(({ reservationId }) =>
        forkJoin([
          this.reservationApi
            .getReservationService(reservationId)
            .pipe(map(({ data }) => this.convertToServiceObject(data))),
          this.reservationApi
            .getReservationServiceCategories(reservationId)
            .pipe(map(({ data }) => data)),
        ]).pipe(
          map(([services, categories]) => {
            categories.forEach((category) => {
              const service = services.find((x) => x.id === category.id);

              if (!service)
                services.unshift({
                  id: category.id,
                  name: category.name,
                  services: [],
                });
            });
            return setReservationServicesAction({ value: services });
          })
        )
      )
    )
  );

  private getReservationSections = createEffect(() =>
    this.actions$.pipe(
      ofType(getReservationSectionAction),
      switchMap(({ reservationId }) =>
        this.reservationApi
          .getReservationSection(reservationId)
          .pipe(
            map(({ data }) => setReservationSectionsAction({ value: data }))
          )
      )
    )
  );

  private getReservationCancellationPolicy = createEffect(() =>
    this.actions$.pipe(
      ofType(getReservationCancellationPolicyAction),
      switchMap(({ reservationId }) =>
        this.reservationApi
          .getReservationCancellationPolicy(reservationId)
          .pipe(
            map(({ data }) =>
              setReservationCancellationPolicysAction({ value: data })
            )
          )
      )
    )
  );

  private getReservationHour = createEffect(() =>
    this.actions$.pipe(
      ofType(getReservationHoursAction),
      switchMap(({ reservationId }) =>
        this.reservationApi
          .getReservationHour(reservationId)
          .pipe(map(({ data }) => setReservationHoursAction({ value: data })))
      )
    )
  );

  private getMenuCategories = createEffect(() =>
    this.actions$.pipe(
      ofType(getMenuCategories),
      switchMap(() =>
        this.reservationApi
          .getMenuCategories()
          .pipe(map(({ data }) => setMenuCategories({ value: data })))
      )
    )
  );

  private convertToServiceObject = (
    data: CityLifeApiMenuItemResponse[]
  ): IServiceCategoryObject[] =>
    data.reduce(
      (
        acc: IServiceCategoryObject[],
        currentItem: CityLifeApiMenuItemResponse
      ) => {
        let categoryObject = acc.find(
          (category) => category.id === currentItem.categoryId
        );

        if (!categoryObject) {
          categoryObject = {
            id:
              currentItem.categoryId !== null
                ? currentItem.categoryId
                : undefined,
            name:
              currentItem.category !== null ? currentItem.category : undefined,
            services: [],
          };
          acc.push(categoryObject);
        }
        categoryObject.services.push({
          id: currentItem.id,
          name: currentItem.name,
          duration:
            currentItem.duration !== null ? currentItem.duration : undefined,
          amount: currentItem.amount,
          description:
            currentItem.description !== null
              ? currentItem.description
              : undefined,
        });

        return acc;
      },
      []
    );
}
