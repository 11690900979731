export interface CityLifeApiBaseResponse {
  message: string;
}
export interface CityLifeApiPayloadResponse<T> extends CityLifeApiBaseResponse {
  data: T;
}
export interface CityLifeApiPaginatedResponse<T>
  extends CityLifeApiBaseResponse {
  data: T[];
  hasNext: boolean;
  hasPrevious: boolean;
  currentPage: number;
}
export interface CityLifeApiErrorResponse extends CityLifeApiBaseResponse {
  error: {
    [key: string]: string;
  };
}
  export interface CitylifeMerchantAddReservation {
    firstname: string;
    lastname: string;
    date: number;
    time: number;
    numberOfGuest: number;
    serviceIds: number[];
    sectionId: number; 
    phoneNumber: number;
    email: number;
    occasion: string;
    note: string;
    reservationType: string;
  }
  

