import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@citylife/shared/data-access/services';

import { CityLifeApiBaseResponse } from '../../models';
import { CL_CONFIG } from '../../app-config';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  constructor(
    private httpService: HttpService,
    @Inject(CL_CONFIG) private appConfig: string
  ) {
    this.httpService.clearHeader();
  }

  public get<TResponse extends CityLifeApiBaseResponse>(
    url: string,
    apiVersion: 'v1' | 'v2' = 'v1'
  ): Observable<TResponse> {
    return this.httpService.get<TResponse>(this.createUrl(url, apiVersion));
  }

  public delete<TResponse extends CityLifeApiBaseResponse>(
    url: string,
    apiVersion: 'v1' | 'v2' = 'v1'
  ): Observable<TResponse> {
    return this.httpService.delete<TResponse>(this.createUrl(url, apiVersion));
  }

  public post<TRequest, TResponse extends CityLifeApiBaseResponse>(
    url: string,
    body: TRequest,
    apiVersion: 'v1' | 'v2' = 'v1'
  ): Observable<TResponse> {
    return this.httpService.post<TRequest, TResponse>(
      this.createUrl(url, apiVersion),
      body
    );
  }

  public put<TRequest, TResponse extends CityLifeApiBaseResponse>(
    url: string,
    body: TRequest,
    apiVersion: 'v1' | 'v2' = 'v1'
  ): Observable<TResponse> {
    return this.httpService.put<TRequest, TResponse>(
      this.createUrl(url, apiVersion),
      body
    );
  }

  private createUrl(url: string, apiVersion: 'v1' | 'v2' = 'v1'): string {
    return `${this.appConfig}/api/${apiVersion}/${url}`;
  }
}
