import { Injectable } from '@angular/core';

import {
  CookieService as NgxCookieService,
  CookieOptions,
} from 'ngx-cookie-service';

import { add } from 'date-fns';

@Injectable({ providedIn: 'root' })
export class CookieService {
  constructor(private readonly cookieService: NgxCookieService) {}

  public get(key: string): string | undefined {
    return this.cookieService.get(this.generateKey(key));
  }

  public set(key: string, value: string, option?: CookieOptions): void {
    let expires: Date | undefined = undefined;
    if (!option || !option.expires) expires = add(Date.now(), { days: 3 });
    this.cookieService.set(this.generateKey(key), value, {
      expires: option?.expires ?? expires,
      sameSite: option?.sameSite ?? 'Strict',
      secure: true,
    });
  }

  public delete(key: string) {
    this.cookieService.delete(this.generateKey(key));
  }

  public deleteAll() {
    this.cookieService.deleteAll();
  }

  private generateKey(key: string): string {
    return `CL-${key}`.toLowerCase();
  }
}
