import { Injectable, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FirebaseError } from '@angular/fire/app';
import {
  Auth,
  User,
  user,
  signInWithEmailAndPassword,
  confirmPasswordReset,
  applyActionCode,
  updatePassword,
} from '@angular/fire/auth';

@Injectable()
export class AuthService {
  private readonly auth$ = inject(Auth);
  private readonly _userSignal: Signal<User | null | undefined> = toSignal(
    user(this.auth$)
  );

  private get user(): User | undefined {
    return this._userSignal() ?? undefined;
  }

  public get name(): string {
    return this.user?.displayName ?? '';
  }

  public get firstname(): string {
    return this.name.split(' ')[0];
  }

  public get phoneNumber(): string {
    return this.user?.phoneNumber ?? '';
  }

  public get loggedIn(): boolean {
    return !!this.user;
  }

  public get emailVerified(): boolean {
    return this.user?.emailVerified ?? false;
  }

  public async getToken(): Promise<string> {
    return (await this.user?.getIdToken()) ?? '';
  }

  public get email(): string {
    return this.user?.email ?? '';
  }

  public async signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<void> {
    try {
      await signInWithEmailAndPassword(this.auth$, email, password);
    } catch (error) {
      throw new Error(this.handleError(error));
    }
  }

  public async signOut(): Promise<void> {
    await this.auth$.signOut();
  }

  public async confirmPasswordReset(
    code: string,
    password: string
  ): Promise<void> {
    try {
      await confirmPasswordReset(this.auth$, code, password);
    } catch (error) {
      return;
    }
  }

  public async changePassword(password: string): Promise<void> {
    if (!this.user) throw new Error('User not authenticated.');
    try {
      await updatePassword(this.user, password);
    } catch (error) {
      throw new Error(this.handleError(error));
    }
  }

  public async verifyEmail(code: string) {
    try {
      await applyActionCode(this.auth$, code);
      if (!this.user) return;

      await this.user.getIdToken(true);
    } catch (error) {
      throw new Error(this.handleError(error));
    }
  }

  private handleError(error: any): string {
    let message = '';

    if (!(error instanceof FirebaseError)) return error['message'];

    switch (error.code) {
      case 'auth/invalid-email':
        message = 'Invalid email';
        break;
      case 'auth/user-not-found':
      case 'auth/wrong-password':
      case 'INVALID_LOGIN_CREDENTIALS':
        message = 'Incorrect Email and password combination.';
        break;

      case 'auth/network-request-failed':
        message =
          'Error connecting to server. Kindly check your internet connection.';
        break;

      case 'auth/invalid-action-code':
        message =
          'Link has already being used or expired. Kindly request a new one.';
        break;

      case 'auth/too-many-requests':
        message =
          'Account temporarily disabled due to many failed login attempts. You can immediately restore it by reseting your password or you can try again later.';
    }

    return message;
  }
}
