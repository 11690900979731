import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { BackendService } from '../backend/backend.service';
import {
  CityLifeApiPaginatedResponse,
  CityLifeApiEntrySearchQuery,
  CityLifeApiEntrySearchResponse,
  CityLifeApiSearchBusinessResponse,
} from '../../models';

@Injectable()
export class SearchApiService {
  constructor(private backendService: BackendService) {}
  private basePath = 'business';

  public search(
    query: CityLifeApiEntrySearchQuery,
    page = 1,
    pageSize = 10
  ): Observable<CityLifeApiPaginatedResponse<CityLifeApiEntrySearchResponse>> {
    const { q, ...others } = query;
    const queryParams: Params = { ...others, page, pageSize };
    if (q && !!q.length) queryParams['q'] = q.join(',');

    return this.backendService.get<
      CityLifeApiPaginatedResponse<CityLifeApiEntrySearchResponse>
    >(`search?${new URLSearchParams(queryParams).toString()}`);
  }

  public searchBusiness(
    query: string,
    page = 1,
    pageSize = 10
  ): Observable<
    CityLifeApiPaginatedResponse<CityLifeApiSearchBusinessResponse>
  > {
    return this.backendService.get<
      CityLifeApiPaginatedResponse<CityLifeApiSearchBusinessResponse>
    >(`search/${this.basePath}?q=${query}&page=${page}&pageSize=${pageSize}`);
  }
}
