import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CityLifeApiBaseResponse,
  CityLifeApiCreateBusinessAccountRequest,
  CityLifeApiMerchantDetailsResponse,
  CityLifeApiPayloadResponse,
  CityLifeApiUpdateMerchantInformationRequest,
} from '../../models';
import { BackendService } from '../backend/backend.service';

@Injectable()
export class AccountApiService {
  private readonly basePath: string = 'accounts/merchant/';
  constructor(private readonly backendService: BackendService) {}

  public createBusinessAccount(
    body: CityLifeApiCreateBusinessAccountRequest
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(`${this.basePath}signup`, body);
  }

  public sendEmailVerificationOrResetPassword(
    email: string,
    action: 'VERIFYEMAIL' | 'RESETPASSWORD'
  ) {
    return this.backendService.post(`${this.basePath}email`, { email, action });
  }

  public getMerchantDetails(): Observable<
    CityLifeApiPayloadResponse<CityLifeApiMerchantDetailsResponse>
  > {
    return this.backendService.get(`${this.basePath}`);
  }

  public updateMerchantInformation(
    body: CityLifeApiUpdateMerchantInformationRequest
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(`${this.basePath}`, body);
  }
}
