import {
  CancellationPolicyObject,
  CityLifeApiEntryFeeObject,
  CityLifeApiOperatingHourObject,
  IdAndName,
  ReservationType,
} from './other';

export interface CityLifeApiEntrySuggestActivityRequest {
  name: string;
  category: string;
  building?: string;
  address: string;
  startDate: string;
  startTime: string;
  endDate?: string;
  endTime?: string;
  description?: string;
  image?: string;
  isFree: boolean;
  entryFees?: CityLifeApiEntryFeeObject[];
  isRecurring: boolean;
  recurringFrequency?: string;
  recurringInterval?: number;
  dateToEnd?: string;
  neverEnd?: boolean;
}

export interface CitylifeApiClaimBusinessRequest {
  type: string;
  file: File;

  nin: string;
  phoneNumber: string;

  rcNumber: string;
  companyType: string;
  companyName: string;
}

export interface CityLifeApiUpdateReservationHourRequest {
  hours: CityLifeApiOperatingHourObject[];
}

export interface CityLifeApiCreateBusinessAccountRequest {
  firstname: string;
  lastname: string;
  businessName?: string;
  email: string;
  telephone: string;
  password: string;
}

export interface CityLifeApiAddBusinessRequest {
  name?: string;
  email?: string;
  telephone?: string;
  address?: string;

  bankName?: string;
  accountNumber?: string;
  accountName?: string;
  logo?: File;
  // bio?: string;
  // state?: number;

  /*
   public string? Name { get; set; }
    public string? Email { get; set; }
    public string? Telephone { get; set; }

    public string? Address { get; set; }


    public string? BankName { get; set; }
    public string? AccountName { get; set; }
    public string? AccountNumber { get; set; }
    public IFormFile? Logo { get; set; }
  */
  // categories?: number[];
}

export type CityLifeApiAddOrEditReservationSectionRequest = IdAndName;

export interface CityLifeApiCreateReservationRequest {
  type?: ReservationType;
  name?: string;
  username?: string;
}

export type CityLifeApiAddOrEditReservationCancellationPolicyRequest =
  CancellationPolicyObject;

export type CityLifeApiAddReservationCategoryRequest = IdAndName;

export interface CityLifeApiAddOrEditReservationServiceRequest {
  id?: number;
  categoryId?: number;
  name: string;
  description?: string;
  duration?: number;
  amount: number;
  type: ReservationType;
}

export interface CitylifeApiUpdateMaxCapacityRequest {
  capacity: number;
}

export interface CityLifeApiUpdateReservationDetailsRequest {
  bankName?: string;
  accountName?: string;
  accountNumber?: string;
  note?: string;
  importantMessage?: string;
  minimumPayment?: number;
  paymentConfirmReservation?: boolean;
}

export interface CityLifeApiUpdateMerchantInformationRequest {
  firstname?: string;
  lastname?: string;
  email?: string;
  phoneNumber?: string;
  position?: string;
}

export interface CitylifeApiAddNotificationRequest {
  endpoint: string;
  auth: string;
  P256DH: string;
}

export interface CityLifeApiVerifyBankAccountRequest {
  bankCode?: string;
  accountNumber?: string;
}

export interface CityLifeApiAddBankAccountRequest {
  subAccountCode?: string;
}
