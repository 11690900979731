import {
  CancellationPolicyObject,
  CityLifeApiEntryFeeObject,
  CityLifeApiEntryShortBusinessDetailsObject,
  CityLifeApiEntryShortEventDetailsObject,
  CityLifeApiOperatingHourObject,
  DepositType,
  OrderObject,
  PaymentStatus,
  ReservationDateObject,
  ReservationStatusType,
  ReservationType,
} from './other';

export type CityLifeApiOperatingHourResponse = CityLifeApiOperatingHourObject;
export interface CityLifeApiBusinessInfoResponse {
  id: string;
  name: string;
  bio?: string;
  address: string;
  longitude: number;
  latitude: number;
  telephone?: string;
  averagePrice: number;
  location?: string;
}
export interface CityLifeApiBusinessPhotoResponse {
  image: string;
  credit?: string;
}

export interface CityLifeApiFullCategoryResponse {
  name: string;
  subCategories: { name: string; value: number }[];
}
export interface CityLifeApiPrimaryCategoryResponse {
  name: string;
  description: string;
  id: number;
  image: string;
}

export interface CityLifeApiCategoryResponse {
  name: string;
  category: number;
  image?: string;
}
export interface CityLifeApiCalendarResponse {
  startDateAndTime: string;
  endDateAndTime: string;
  summary: string;
  description: string;
  address: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

export interface CityLifeApiFullEventDetailsResponse {
  id: string;
  title: string;
  businessName: string;
  description: string;
  address: string;
  date: string;
  time: string;
  entryFees: CityLifeApiEntryFeeObject[];
  category: string;
  distance: number;
  isFree: boolean;
  location?: string;
  // features: string[];
  startDateTime: string;
  longitude: number;
  latitude: number;
  numberOfTimesAddedToCalendar: number;
  image: string;
  slug: string;
}

export interface CityLifeApiEntrySearchResponse {
  type: 'EVENT' | 'BUSINESS' | 1 | 2;
  business?: CityLifeApiEntryShortBusinessDetailsObject;
  event?: CityLifeApiEntryShortEventDetailsObject;
}

export interface CityLifeApiSearchBusinessResponse {
  id: string;
  name: string;
  address: string;
  image?: string;
}

export interface CityLifeApiReservationToolResponse {
  id: string;
  name: string;
  username: string | null;
  type: ReservationType;
  capacity: number;

  note: string | null;
  termsOfService: string | null;

  minimumPayment: number;
  paymentConfirmReservation: boolean;

  acceptDeposit: boolean;
  depositAmount: number | null;
  depositType: DepositType | null;
}

export interface CityLifeApiMerchantDetailsResponse {
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  position: string | null;
}

export interface CityLifeApiMenuItemResponse {
  id: number;
  name: string;
  description: string | null;
  duration: number | null;
  amount: number;
  categoryId: number | null;
  category: string | null;
}

export interface CityLifeApiMerchantBusinessDetailsResponse {
  name: string | null;
  logo: string | null;

  email: string | null;
  telephone: string | null;
  address: string | null;

  accountName: string | null;
  bankName: string | null;
  accountNumber: string | null;
}

export interface CityLifeApiReservationResponse {
  id: number;
  name: string;
  guest: number;
  dateTime: string;
  reservationToolId: string;
  status: ReservationStatusType;
}

export interface CityLifeApiReservationDetailsResponse {
  id: number;
  customer: string;
  status: ReservationStatusType;
  section?: string;
  amount?: number;
  phoneNumber: string;
  occassion?: string;
  orders: OrderObject[];
  dates: ReservationDateObject[];
  note?: string;
}

export interface CityLifeApiReservationToolDetailsResponse {
  id: string;
  businessName: string;
  logo?: string;
  note?: string;
  termsOfService?: string;
  address: string;
  phoneNumber: string;
  type: ReservationType;
  minimumPaymentRequiredInPercentage: number;
}

export interface CityLifeApiMakeReservationRequest {
  firstname?: string;
  lastname?: string;
  email?: string;
  date?: string;
  time?: string;
  numberOfGuest?: number;
  serviceIds?: number[];
  sectionId?: number;
  phoneNumber?: string;
  occasion?: string;
  note?: string;
  reservationType?: ReservationType;
}

export interface CityLifeApiCustomerReservationDetailsResponse {
  id: number;
  name: string;
  email: string;
  guest: number;
  phoneNumber: string;
  businessName: string;
  businessAddress: string;
  businessPhoneNumber: string;
  businessNote?: string;
  status: ReservationStatusType;
  section?: string;
  amount?: number;
  occasion?: string;
  policy: CancellationPolicyObject[];
  orders: OrderObject[];
  dates: ReservationDateObject[];
  note?: string;
  termsOfService?: string;
  paymentStatus?: PaymentStatus;
  amountPaid?: number;
  minimumAmountToPay?: number;
  serviceCharge?: number;
  duration: number;
  deposit?: number;
  orderTotal?: number;
  paymentRequired: boolean;
  paymentPercentage?: number;
  minimumPaymentRequiredInPercentage: number;
  reservationToolId: string;
  maxRescheduleDate?: string;
  subAccount?: string;
}

export interface CitylifeApiNotificationServerKeyResponse {
  serverPublicKey: string;
}

export interface CityLifeApiBankAccountResponse {
  id: number;
  name: string;
  bankName: string;
  accountNumber: string;
  favourite: boolean;
}

export interface CityLifeApiBanksResponse {
  name: string;
  code: string;
}

export interface CityLifeApiVerifyBankAccountResponse {
  name: string;
  bankName: string;
  accountNumber: string;
  subAccountCode: string;
}
