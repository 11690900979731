import { makeEnvironmentProviders } from '@angular/core';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
  NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
  IGoogleAnalyticsCommand,
  IGoogleAnalyticsSettings,
} from 'ngx-google-analytics';

export const provideAppAnalytics = (
  trackingCode: string,
  initCommands: IGoogleAnalyticsCommand[] = [],
  uri?: string,
  enableTracing?: boolean,
  nonce?: string
) => {
  return makeEnvironmentProviders([
    NgxGoogleAnalyticsModule,
    NgxGoogleAnalyticsRouterModule,
    {
      provide: NGX_GOOGLE_ANALYTICS_SETTINGS_TOKEN,
      useValue: {
        trackingCode,
        initCommands,
        uri,
        enableTracing,
        nonce,
      } as IGoogleAnalyticsSettings,
    },
  ]);
};
