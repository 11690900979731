export class DashboardRouterUtil {
  static Routes = {
    Root: '',
    Home: {
      Root: 'home',
      VerifyEmail: 'verify',
    },
    Tool: {
      Root: 'tool',
      Pricing: 'pricing',
      New: 'new',
      // Name: 'name',
      Hour: 'hour',
      Service: 'service',
      Section: 'section',
      // Logo: 'logo',
      Policy: 'policy',
    },
    Product: 'product',
    Reservation: {
      Root: 'reservations',
    },
    Settings: {
      Root: 'settings',
      User: 'user',
      Business: 'business',
    },
    Business: {
      Root: 'business',
      Add: 'add',
      Detail: 'detail',
      Claim: 'claim',
      Complete: 'complete',
    },
    Auth: {
      Root: 'auth',
      Signup: 'signup',
      Login: 'login',
      ForgotPassword: 'forgot-password',
      ResetPassword: 'reset-password',
      Verify: 'verify',
    },
  };

  static Params = {
    BusinessId: 'businessId',
    ReservationToolId: 'reservationToolId',
    RedirectUri: 'redirect_uri',
  };
}
