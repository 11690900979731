import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

export interface IToast {
  status: 'success' | 'error';
  message: string;
  align: boolean;
}

@Injectable()
export class ToastService {
  private _data: Subject<IToast | undefined> = new ReplaySubject(1);

  public get data$(): Observable<IToast | undefined> {
    return this._data.asObservable();
  }

  public set(
    message: string,
    status: IToast['status'] = 'error',
    align: IToast['align'] = false,
    displayDurationInSeconds = 3
  ) {
    if (!message) return;

    this._data.next({ status, message, align });

    setTimeout(() => this.hide(), 1000 * displayDurationInSeconds);
  }

  private hide(): void {
    this._data.next(undefined);
  }
}
