import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpService {
  private headers: HttpHeaders;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
  }

  public get<TResponse>(url: string): Observable<TResponse> {
    return this.http.get<TResponse>(url, {
      headers: this.headers,
    });
  }

  public delete<TResponse>(url: string): Observable<TResponse> {
    return this.http.delete<TResponse>(url, {
      headers: this.headers,
    });
  }

  public post<TRequest, TResponse>(
    url: string,
    body: TRequest
  ): Observable<TResponse> {
    return this.http.post<TResponse>(url, body, {
      headers: this.headers,
    });
  }

  public put<TRequest, TResponse>(
    url: string,
    body: TRequest
  ): Observable<TResponse> {
    return this.http.put<TResponse>(url, body, {
      headers: this.headers,
    });
  }

  public jsonp<TResponse>(url: string, callbackParam = 'callback') {
    return this.http.jsonp<TResponse>(url, callbackParam);
  }

  public clearHeader(): HttpService {
    const keys = this.headers.keys();

    for (const key of keys) this.headers = this.headers.delete(key);

    return this;
  }

  public addHeader(name: string, value: string | string[]): HttpService {
    this.headers = this.headers.set(name, value);
    return this;
  }
}
