import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  deleteBankAccountAction,
  favouriteBankAccountAction,
  getBankAccountsAction,
  getBanksAction,
  getBusinessDetailsAction,
  getMerchantDetailsAction,
  getMerchantReservationAction,
  setAppErrorAction,
  setBankAccountsAction,
  setBanksAction,
  setBusinessDetailsAction,
  setMerchantDetailsAction,
  setMerchantReservationAction,
} from './app.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  AccountApiService,
  BankApiService,
  BusinessApiService,
  ReservationApiService,
} from '@citylife/shared/data-access/citylife-api';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AppEffect {
  constructor(
    private readonly actions$: Actions,
    private readonly reservationApi: ReservationApiService,
    private readonly accountApi: AccountApiService,
    private readonly businessApi: BusinessApiService,
    private readonly bankApi: BankApiService
  ) {}

  private getMerchantReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantReservationAction),
      switchMap(() =>
        this.reservationApi.getMerchantReservations().pipe(
          map(({ data }) =>
            setMerchantReservationAction({
              value: data.map((value) => ({
                ...value,
                username: value.username === null ? '' : value.username,
                note: value.note === null ? '' : value.note,
                termsOfService:
                  value.termsOfService === null ? '' : value.termsOfService,
                depositAmount:
                  value.depositAmount === null ? 0 : value.depositAmount,
                depositType:
                  value.depositType === null ? 'PER_GUEST' : value.depositType,
              })),
            })
          ),
          catchError((err: HttpErrorResponse) =>
            of(setAppErrorAction({ value: err.error?.message ?? err.message }))
          )
        )
      )
    )
  );

  private getMerchantDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(getMerchantDetailsAction),
      switchMap(() =>
        this.accountApi.getMerchantDetails().pipe(
          map(({ data }) =>
            setMerchantDetailsAction({
              value: {
                ...data,
                position: data.position ?? '',
              },
            })
          )
        )
      )
    )
  );

  private getMerchantBusinessDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(getBusinessDetailsAction),
      switchMap(() =>
        this.businessApi.getMerchantBusinessDetails().pipe(
          map(({ data }) =>
            setBusinessDetailsAction({
              value: {
                email: data.email ?? '',
                // accountName: data.accountName ?? '',
                // accountNumber: data.accountNumber ?? '',
                address: data.address ?? '',
                // bankName: data.bankName ?? '',
                logo: data.logo ?? '',
                name: data.name ?? '',
                telephone: data.telephone ?? '',
              },
            })
          )
        )
      )
    )
  );

  private getBanks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBanksAction),
      switchMap(() =>
        this.bankApi
          .getBanks()
          .pipe(map(({ data }) => setBanksAction({ value: data })))
      )
    )
  );

  private getBankAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBankAccountsAction),
      switchMap(() =>
        this.bankApi
          .getBankAccounts()
          .pipe(map(({ data }) => setBankAccountsAction({ value: data })))
      )
    )
  );

  private deleteBankAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteBankAccountAction),
      switchMap(({ id }) =>
        this.bankApi
          .deleteBankAccountBank(id)
          .pipe(map(() => getBankAccountsAction()))
      )
    )
  );

  private favouriteBankAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(favouriteBankAccountAction),
      switchMap(({ id }) =>
        this.bankApi
          .favouriteBankAccountBank(id)
          .pipe(map(() => getBankAccountsAction()))
      )
    )
  );
}
