import {
  BusinessSearchObject,
  CategoryObject,
} from '@citylife/dashboard/shared/utils';
import {
  ICancellationPolicy,
  IOperatingHourObject,
  ISectionObject,
  IServiceCategoryObject,
  IdAndName,
} from '@citylife/shared/data-access/models';
import { createAction, props } from '@ngrx/store';

export const getBusinessAction = createAction(
  '[Settings] Get Business',
  props<{ value: string }>()
);

export const setBusinessAction = createAction(
  '[Settings] Set Businesses',
  props<{ value: BusinessSearchObject[] }>()
);

export const getCategoriesAction = createAction('[Settings] Get Categories');

export const setCategoriesAction = createAction(
  '[Dashboard] Set Categories',
  props<{ value: CategoryObject[] }>()
);

export const getReservationServicesAction = createAction(
  '[Settings] Get Reservation Services',
  props<{ reservationId: string }>()
);

export const setReservationServicesAction = createAction(
  '[Settings] Set Reservation services',
  props<{ value: IServiceCategoryObject[] }>()
);

export const getMenuCategories = createAction('[Settings] Get Menu Categories');

export const setMenuCategories = createAction(
  '[Settings] Set Menu Categories',
  props<{ value: IdAndName[] }>()
);

export const getReservationSectionAction = createAction(
  '[Settings] Get Reservation Section',
  props<{ reservationId: string }>()
);

export const setReservationSectionsAction = createAction(
  '[Settings] Set Reservation Section',
  props<{ value: ISectionObject[] }>()
);

export const getReservationCancellationPolicyAction = createAction(
  '[Settings] Get Reservation Cancellation Policy',
  props<{ reservationId: string }>()
);

export const setReservationCancellationPolicysAction = createAction(
  '[Settings] Set Reservation Cancellation Policy',
  props<{ value: ICancellationPolicy[] }>()
);

export const getReservationHoursAction = createAction(
  '[Settings] Get Reservation Hours',
  props<{ reservationId: string }>()
);

export const setReservationHoursAction = createAction(
  '[Settings] Set Reservation Hours',
  props<{ value: IOperatingHourObject[] }>()
);
