import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from './app.reducers';

export const APP_FEATURE_KEY = 'app';

const selectAppState = createFeatureSelector<AppState>(APP_FEATURE_KEY);

export const selectAppLoadingStatus = createSelector(
  selectAppState,
  (state) => state.status
);

export const selectReservationTools = createSelector(
  selectAppState,
  ({ data }) => data.reservations
);

export const selectReservationTool = (id?: string) =>
  createSelector(selectReservationTools, (reservations) => {
    if (!id) return reservations[0];
    return reservations.find((x) => x.id === id);
  });

export const selectMerchantDetails = createSelector(
  selectAppState,
  ({ data }) => data.merchant
);

export const selectMerchantBusinessDetails = createSelector(
  selectAppState,
  ({ data }) => data.business
);

export const selectBankAccounts = createSelector(
  selectAppState,
  ({ data }) => data.bankAccounts
);

export const selectBanks = createSelector(
  selectAppState,
  ({ data }) => data.banks
);
