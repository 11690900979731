<div
  class="relative flex justify-center px-6 md:px-0 z-[999]"
  [ngClass]="data.align ? 'md:left-[18%] xl:left-1/5' : ''"
  *ngIf="data$ | async as data"
>
  <div
    class="absolute top-[18px] md:top-[72px] border-l-4 border-solid rounded-[5px] w-4/5 md:w-[65%] lg:w-[45%]"
    [ngClass]="{
      'border-error bg-[#FEE7E4] text-[#FF220C]': data.status === 'error',
      'border-green-900 bg-green-100 text-green-500': data.status === 'success',
    }"
  >
    <p class="text-sm text-left pl-[22px] pr-[29px] py-[9px] md:py-[18px]">
      {{ data.message }}
    </p>
  </div>
</div>
