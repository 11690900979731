import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PwaUpdatesService } from '@citylife/shared/data-access/services';
import { ToastComponent } from '@citylife/shared/ui/toast';
@Component({
  standalone: true,
  imports: [ToastComponent, RouterOutlet],
  selector: 'cl-root',
  template: `
    <cl-toast></cl-toast>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  constructor(private readonly pwaUpdate: PwaUpdatesService) {}

  ngOnInit(): void {
    this.pwaUpdate.checkUpdate();
  }
}
