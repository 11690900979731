@if (loading$ | async | loading) {
<div class="modal">
  <span class="spinner-loader"></span>
</div>
} @else {
<div
  class="flex flex-col gap-[28.5px] px-[30px] pt-[9px] md:gap-[40px] md:pt-10 md:px-14 lg:flex-row lg:h-screen lg:p-4 lg:pb-0 lg:gap-[60px] lg:overflow-hidden"
>
  <nav class="flex flex-row justify-between items-center lg:hidden">
    <a class="cursor-pointer" routerLink="/">
      <img clAddLogo class="w-[125px] md:w-[154.667px]" />
    </a>
    <div class="flex gap-14">
      <div id="signOut" class="hidden md:block lg:hidden">
        <ng-container *ngTemplateOutlet="userName"></ng-container>
      </div>

      <div
        id="navbar"
        class="flex flex-col bg-primary rounded-[5px] cursor-pointer items-center justify-center md:self-center"
        (click)="openNav()"
      >
        <mat-icon>menu</mat-icon>
      </div>
    </div>
  </nav>
  @if (displayNav()) {
  <div class="modal flex flex-row justify-between lg:hidden">
    <mat-icon
      fontSet="material-symbols-outlined"
      class="self-start mt-[87px] ml-[30px] cursor-pointer"
      (click)="closeNav()"
      >cancel</mat-icon
    >
    <div id="navopen" class="w-[70%] md:w-2/5 h-screen ml-auto">
      <ng-container *ngTemplateOutlet="nav"></ng-container>
    </div>
  </div>
  }

  <div class="hidden lg:block w-[18%] xl:w-[20%] h-screen pb-8">
    <ng-container *ngTemplateOutlet="nav"></ng-container>
  </div>
  <div class="flex flex-col gap-[14px] w-full pb-4">
    <div id="signOut" class="hidden lg:block">
      <ng-container *ngTemplateOutlet="userName"></ng-container>
    </div>

    <div class="lg:overflow-auto h-full">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
}

<ng-template #nav>
  <nav
    class="flex flex-col gap-[50px] bg-[#282D42] rounded-[20px] rounded-br-none rounded-tr-none lg:rounded-[20px] px-6 lg:px-auto pt-[34px] h-screen pb-12 lg:pb-0"
  >
    <a class="cursor-pointer mx-auto" routerLink="/home">
      <img
        clAddLogo
        type="alt"
        class="w-[156px] md:w-[154.667px] lg:w-[155.531px] h-14"
      />
    </a>
    <div class="flex flex-col gap-10 flex-grow">
      <ul class="flex flex-col gap-2 pl-[6px]">
        @for (item of navItems; track item.route) {
        <li (click)="closeNav()">
          <a
            class="flex flex-row gap-4 pl-3 py-[7.5px] items-center cursor-pointer text-[#DADADA]"
            [routerLink]="['/', item.route]"
            routerLinkActive="bg-white rounded-l-[5px]"
          >
            <div class="flex flex-col">
              <mat-icon routerLinkActive="text-[#282D42]">{{
                item.icon
              }}</mat-icon>
            </div>
            <span
              class="text-sm font-medium"
              routerLinkActive="text-[#282D42]"
              >{{ item.title }}</span
            >
          </a>
        </li>
        }
      </ul>
      <div class="flex flex-col pb-[60px] gap-3 mt-auto lg:hidden">
        <ng-container *ngTemplateOutlet="addReservation"></ng-container>
        <button
          class="flex flex-row items-center pl-[18px] py-[7.5px] gap-4 cursor-pointer border-b border-b-[#DADADA]"
          (click)="signOut()"
        >
          <mat-icon class="text-[#D42620]">logout</mat-icon>
          <span class="text-[#DADADA]">Log out</span>
        </button>
      </div>
    </div>
  </nav>
</ng-template>

<ng-template #userName>
  <div class="hidden gap-4 md:flex justify-end">
    <div class="md:hidden lg:block">
      <ng-container *ngTemplateOutlet="addReservation"></ng-container>
    </div>

    <div class="flex flex-col relative">
      <div
        (click)="openDropdown()"
        class="flex flex-row items-center gap-2 border-primary border-solid border-[0.5px] p-[5px] rounded-full cursor-pointer text-sm"
      >
        <span class="bg-primary rounded-full h-full p-[11px] capitalize">{{
          abbr
        }}</span>
        <span>{{ fullname }}</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>

      @if (usernameDropdown) {
      <div
        class="bg-white px-5 py-3 rounded-xl z-50 absolute top-full w-full left-0 self-center mt-1"
        style="
          box-shadow: 0px 4px 29px 0px rgba(146, 149, 152, 0.08),
            0px 4px 29px 0px rgba(0, 0, 0, 0.12);
        "
      >
        <div class="cursor-pointer flex gap-2" (click)="signOut()">
          <mat-icon class="text-[#D42620]">logout</mat-icon>
          <span>Log Out</span>
        </div>
      </div>
      }
    </div>
  </div>
</ng-template>

<ng-template #addReservation>
  <button class="btn btn-outlined gap-2" (click)="openModal()">
    <p></p>
    <p></p>
    <p></p>
    <p></p>
    <mat-icon>add_circle</mat-icon>
    Add reservation
  </button>
</ng-template>

@if(addReservationModal){
<div class="modal justify-center items-center md:justify-end md:items-end">
  <div class="md:mt-auto lg:w-[30%]">
    <cl-merchant-add-reservation
      (closeModal)="cancel()"
    ></cl-merchant-add-reservation>
  </div>
</div>

}
