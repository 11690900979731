<div class="relative flex flex-col gap-4">
  <div class="form-group" id="showSelect">
    <div (click)="toggle()" class="form-control-group">
      <span class="whitespace-nowrap">
        {{ placeholder }}
      </span>

      <mat-icon class="ml-auto">keyboard_arrow_down</mat-icon>
    </div>

    @if (showSelect()) {
    <div
      class="absolute w-full border flex flex-col bottom-[110%] gap-2 bg-white z-30 rounded-[5px] max-h-[250px] overflow-y-scroll"
      style="
        box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
          0px 10px 15px -3px rgba(0, 0, 0, 0.1),
          0px 0px 0px 1px rgba(0, 0, 0, 0.05);
      "
    >
      <div class="form-control-group">
        <input
          #input
          type="text"
          class="form-control"
          (input)="onChange(input.value.toLowerCase())"
        />
        <mat-icon
          class="cursor-pointer"
          (click)="input.value = ''; onChange('')"
          >close</mat-icon
        >
      </div>
      @if (optionsSignal().length) { @for (option of optionsSignal(); track
      $index + option.value + option.label) {

      <div class="flex flex-col gap-3 pl-3">
        <span
          ><label class="text-base font-semibold">
            @if (!group) {
            <input
              type="checkbox"
              [checked]="isChecked(option.value)"
              (click)="onSelect(option.value, option.label)"
            />
            }
            {{ option.label }}</label
          ></span
        >
        @if (group && option?.items; as items) { @for (item of items; track
        $index) {
        <span class="flex items-start"
          ><label
            class="text-sm font-normal flex gap-2 justify-center items-center"
          >
            <input
              type="checkbox"
              [checked]="isChecked(item.value)"
              (click)="onSelect(item.value, item.label)"
            />
            {{ item.label }}</label
          ></span
        >
        } }
      </div>
      } } @else {
      <span>No result found</span>
      }
    </div>
    }
  </div>
  <!-- <div class="flex flex-row gap-2 overflow-x-scroll">
    @for (value of valueSignal(); track $index) {
    <button
      type="button"
      class="flex flex-row max-w-fit items-center gap-2 bg-primary rounded-[5px] whitespace-nowrap"
    >
      <span class="p-2 whitespace-nowrap">{{ value.label }}</span>
      <mat-icon (click)="onSelect(value.value, value.label)">clear</mat-icon>
    </button>
    }
  </div> -->
</div>
