import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import {
  CityLifeApiBaseResponse,
  CitylifeApiClaimBusinessRequest,
} from '../../models';

@Injectable()
export class ClaimApiService {
  private readonly basePath = 'claims';
  constructor(private backendService: BackendService) {}

  public claimBusiness(
    businessId: string,
    body: Partial<CitylifeApiClaimBusinessRequest>
  ) {
    const formData = new FormData();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const key in body) formData.append(key, body[key]);

    return this.backendService.post<FormData, CityLifeApiBaseResponse>(
      `${this.basePath}/${businessId}`,
      formData
    );
  }
}
