const CITYLIFE = 'CityLife';

export const formatTitle = (
  title: string,
  platform?: 'Business' | 'Dashboard'
): string => {
  if (platform) return `${CITYLIFE} ${platform} | ${title}`;

  return `${title} | CityLife`;
};
