import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import { Observable } from 'rxjs';
import {
  CityLifeApiAddOrEditReservationCancellationPolicyRequest,
  CityLifeApiAddOrEditReservationSectionRequest,
  CityLifeApiAddOrEditReservationServiceRequest,
  CityLifeApiAddReservationCategoryRequest,
  CityLifeApiBaseResponse,
  CityLifeApiCreateReservationRequest,
  CityLifeApiOperatingHourResponse,
  CityLifeApiPayloadResponse,
  CityLifeApiReservationToolResponse,
  CityLifeApiUpdateReservationHourRequest,
  CitylifeApiUpdateMaxCapacityRequest,
  CancellationPolicyObject,
  IdAndName,
  CityLifeApiUpdateReservationDetailsRequest,
  CityLifeApiMenuItemResponse,
  CityLifeApiReservationResponse,
  CityLifeApiReservationDetailsResponse,
  ReservationStatusType,
  CityLifeApiReservationToolDetailsResponse,
  CityLifeApiMakeReservationRequest,
  CityLifeApiCustomerReservationDetailsResponse,
  PaymentStatus,
  CitylifeMerchantAddReservation,
} from '../../models';

@Injectable()
export class ReservationApiService {
  private basePath = 'reservations';
  constructor(private backendService: BackendService) {}

  public createReservation(
    body: CityLifeApiCreateReservationRequest
  ): Observable<CityLifeApiPayloadResponse<Required<IdAndName<string>>>> {
    return this.backendService.post(`${this.basePath}/new`, body);
  }

  public updateReservationHour(
    body: CityLifeApiUpdateReservationHourRequest,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(
      `${this.basePath}/${reservationId}/hour`,
      body
    );
  }

  public getReservationHour(
    reservationId: string
  ): Observable<
    CityLifeApiPayloadResponse<CityLifeApiOperatingHourResponse[]>
  > {
    return this.backendService.get(`${this.basePath}/${reservationId}/hour`);
  }

  public addOrEditReservationSection(
    body: CityLifeApiAddOrEditReservationSectionRequest,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(
      `${this.basePath}/${reservationId}/section`,
      body
    );
  }
  public getReservationSection(
    reservationId: string
  ): Observable<CityLifeApiPayloadResponse<Required<IdAndName>[]>> {
    return this.backendService.get(`${this.basePath}/${reservationId}/section`);
  }

  public deleteReservationSection(
    id: number,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.delete(
      `${this.basePath}/${reservationId}/section/${id}`
    );
  }

  public addOrEditReservationCancellationPolicy(
    body: CityLifeApiAddOrEditReservationCancellationPolicyRequest,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(
      `${this.basePath}/${reservationId}/policy/cancel`,
      body
    );
  }
  public getReservationCancellationPolicy(
    reservationId: string
  ): Observable<CityLifeApiPayloadResponse<CancellationPolicyObject[]>> {
    return this.backendService.get(
      `${this.basePath}/${reservationId}/policy/cancel`
    );
  }

  public deleteReservationCancellationPolicy(
    hour: number,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.delete(
      `${this.basePath}/${reservationId}/policy/cancel/${hour}`
    );
  }

  public addOrEditReservationCategory(
    body: CityLifeApiAddReservationCategoryRequest,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(
      `${this.basePath}/${reservationId}/category`,
      body
    );
  }

  public addOrEditReservationService(
    body: CityLifeApiAddOrEditReservationServiceRequest,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(
      `${this.basePath}/${reservationId}/service`,
      body
    );
  }

  public getReservationService(
    reservationId: string
  ): Observable<CityLifeApiPayloadResponse<CityLifeApiMenuItemResponse[]>> {
    return this.backendService.get(`${this.basePath}/${reservationId}/service`);
  }

  public deleteReservationService(
    id: number,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.delete(
      `${this.basePath}/${reservationId}/service/${id}`
    );
  }

  public deleteReservationCategory(
    id: number,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.delete(
      `${this.basePath}/${reservationId}/category/${id}`
    );
  }

  public getMerchantReservations(): Observable<
    CityLifeApiPayloadResponse<CityLifeApiReservationToolResponse[]>
  > {
    return this.backendService.get(`${this.basePath}/tool`);
  }

  public updateReservationCapacity(
    body: CitylifeApiUpdateMaxCapacityRequest,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(
      `${this.basePath}/${reservationId}/capacity`,
      body
    );
  }

  public updateReservationDetails(
    body: CityLifeApiUpdateReservationDetailsRequest,
    reservationId: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(
      `${this.basePath}/${reservationId}/detail`,
      body
    );
  }

  public getMenuCategories(): Observable<
    CityLifeApiPayloadResponse<Required<IdAndName>[]>
  > {
    return this.backendService.get(`menus/category`);
  }

  public getReservationServiceCategories(
    reservationId: string
  ): Observable<CityLifeApiPayloadResponse<Required<IdAndName>[]>> {
    return this.backendService.get(
      `${this.basePath}/${reservationId}/category`
    );
  }

  public getReservations(
    lastIndex: number,
    pageSize = 10,
    status?: string
  ): Observable<CityLifeApiPayloadResponse<CityLifeApiReservationResponse[]>> {
    return this.backendService.get(
      `${this.basePath}?status=${status}&lastIndex=${lastIndex}&pageSize=${pageSize}`
    );
  }

  public getReservationDetails(
    id: number
  ): Observable<
    CityLifeApiPayloadResponse<CityLifeApiReservationDetailsResponse>
  > {
    return this.backendService.get(`${this.basePath}/${id}`);
  }

  public updateReservationStatus(
    id: number,
    status: ReservationStatusType
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.put(`${this.basePath}/${id}/status`, { status });
  }

  public getReservationToolDetails(
    reservationTooldId: string
  ): Observable<
    CityLifeApiPayloadResponse<CityLifeApiReservationToolDetailsResponse>
  > {
    return this.backendService.get(
      `${this.basePath}/tool/${reservationTooldId}`
    );
  }

  public getAvailableTime(
    reservationToolId: string,
    date: string,
    duration?: number
  ): Observable<CityLifeApiPayloadResponse<string[]>> {
    return this.backendService.get(
      `${this.basePath}/${reservationToolId}/time?date=${date}&duration=${duration}`
    );
  }

  public makeReservation(
    reservationToolId: string,
    body: CityLifeApiMakeReservationRequest
  ): Observable<CityLifeApiPayloadResponse<{ id: string }>> {
    return this.backendService.post(
      `${this.basePath}/${reservationToolId}/make`,
      body
    );
  }

  public getCustomerReservationDetails(
    id: number,
    email: string
  ): Observable<
    CityLifeApiPayloadResponse<CityLifeApiCustomerReservationDetailsResponse>
  > {
    return this.backendService.get(
      `${this.basePath}/${id}/details?email=${email}`
    );
  }

  public initiateReservationPayment(
    id: number,
    email: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.get(
      `${this.basePath}/${id}/payment/initiate?email=${email}`
    );
  }

  public verifyReservationPayment(
    id: number,
    reference: string,
    email: string
  ): Observable<CityLifeApiPayloadResponse<{ status: PaymentStatus }>> {
    return this.backendService.get(
      `${this.basePath}/${id}/payment/${reference}?email=${email}`
    );
  }

  public rescheduleReservation(
    reservationId: number,
    reservationToolId: string,
    body: { date: string; time: string; email: string }
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(
      `${this.basePath}/${reservationToolId}/reschedule/${reservationId}`,
      body
    );
  }
  public cancelReservation(
    reservationId: number,
    email: string
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.get(
      `${this.basePath}/${reservationId}/status/cancel?email=${email}`
    );
  }

  public getReservationToolsImages(
    reservationToolId: string
  ): Observable<CityLifeApiPayloadResponse<{ id: number; link: string }[]>> {
    return this.backendService.get(
      `${this.basePath}/${reservationToolId}/images`
    );
  }

  public addReservationByMerchant(
    body: CitylifeMerchantAddReservation,
    reservationId: string
  ): Observable<CityLifeApiPayloadResponse<{ id: string }>> {
    return this.backendService.post(
      `${this.basePath}/${reservationId}/make/merchant`,
      body
    );
  }
}
