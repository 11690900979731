import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Output,
  Signal,
  WritableSignal,
  computed,
  effect,
  signal,
  untracked,
} from '@angular/core';
import { MultiSelectInputComponent } from '@citylife/shared/ui/multiselect-input';
import { selectRouteParam } from '@citylife/shared/providers';
import { DashboardRouterUtil } from '@citylife/dashboard/shared/utils';
import { Store } from '@ngrx/store';
import {
  getReservationServicesAction,
  selectReservationServices,
  selectReservationTools,
} from '@citylife/dashboard/shared/data-access/store';
import { ReservationApiService } from '@citylife/shared/data-access/citylife-api';
import { ToastService } from '@citylife/shared/ui/toast';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { InputDirective } from '@citylife/shared/directives/input';
import { AsyncPipe, DOCUMENT } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { SelectItemGroup } from 'primeng/api';
import { lastValueFrom } from 'rxjs';

interface Error {
  firstname?: string;
  lastname?: string;
  email?: string;
  date?: string;
  time?: string;
  guest?: string;
  numberOfGuest?: string;
  serviceIds?: string;
  sectionId?: string;
  phoneNumber?: string;
  occasion?: string;
  note?: string;
}

@Component({
  selector: 'cl-merchant-add-reservation',
  standalone: true,
  imports: [
    AsyncPipe,
    ReactiveFormsModule,
    InputDirective,
    MatIconModule,
    MultiSelectInputComponent,
  ],
  templateUrl: './merchant-add-reservation.component.html',
  styleUrl: './merchant-add-reservation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MerchantAddReservationComponent {
  protected form: FormGroup;
  private isRequest = false;
  protected readonly errorSignal: WritableSignal<Error> = signal({});
  @Output() closeModal = new EventEmitter<void>();

  private toolSignal = computed(
    () => this.store.selectSignal(selectReservationTools)()[0]
  );

  protected groupedServicesSignal: Signal<SelectItemGroup[]> = computed(() => {
    const groupedServices = this.store.selectSignal(selectReservationServices)()
      .data;

    return groupedServices.map((service) => ({
      label: service.name ?? 'Uncategorised',
      value: service.id,
      items: service.services.map((service) => ({
        label: service.name,
        value: service.id,
      })),
    }));
  });

  constructor(
    private fb: FormBuilder,
    private readonly reservationApi: ReservationApiService,
    private readonly toastService: ToastService,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly store: Store
  ) {
    effect(() => {
      const tool = this.toolSignal();
      if (!tool) return;

      untracked(() =>
        this.store.dispatch(
          getReservationServicesAction({ reservationId: tool.id })
        )
      );
    });

    this.form = this.fb.nonNullable.group({
      firstName: this.fb.nonNullable.control(''),
      lastName: this.fb.nonNullable.control(''),
      email: this.fb.nonNullable.control(''),
      phoneNumber: this.fb.nonNullable.control(''),
      date: this.fb.nonNullable.control(''),
      time: this.fb.nonNullable.control(''),
      serviceIds: this.fb.nonNullable.control([]),
      numberOfGuest: this.fb.nonNullable.control(1),
    });
  }

  protected setServices(services: never[]) {
    this.form.get('serviceIds')?.setValue(services);
  }

  protected get groupedServices(): string {
    return JSON.stringify(this.groupedServicesSignal());
  }

  protected reservationToolIdSignal: Signal<string> = computed(() =>
    this.store.selectSignal(
      selectRouteParam(DashboardRouterUtil.Params.ReservationToolId)
    )()
  );

  protected async submit() {
    if (this.isRequest) return;

    this.isRequest = true;
    try {
      await lastValueFrom(
        this.reservationApi.addReservationByMerchant(
          { ...this.form.value, reservationType: this.toolSignal().type },
          this.toolSignal().id
        )
      );
      this.form.reset();
      this.toastService.set('Reservation added!', 'success');
      this.cancel();
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        const err = error['error']['error'] as Error;
        if (Object.keys(err).length > 0) this.errorSignal.update(() => err);
        else this.toastService.set(error['error']['message'], 'error');
      }
    } finally {
      this.isRequest = false;
    }
  }

  protected cancel() {
    this.closeModal.emit();
  }
}
