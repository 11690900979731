import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  WritableSignal,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

interface Option {
  label: string;
  value: never;
  items?: { label: string; value: never }[];
}

@Component({
  selector: 'cl-multiselect-input',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './multiselect-input.component.html',
  styleUrl: './multiselect-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectInputComponent implements OnChanges {
  @Input({ required: true }) options: string = '';
  @Input() group: boolean = false;
  @Input({ required: true }) placeholder: string = '';
  @Output() valueChange: EventEmitter<never[]> = new EventEmitter();
  private allOptionsSignal: WritableSignal<Option[]> = signal([]);

  protected optionsSignal: WritableSignal<Option[]> = signal([]);

  protected showSelect = signal(false);
  protected valueSignal: WritableSignal<{ label: string; value: never }[]> =
    signal([]);

  @HostListener('document:click', ['$event'])
  private onDocumentClick(event: Event): void {
    if (!this.showSelect()) {
      return;
    }
    const clickedElement = event.target as HTMLElement;

    if (this.showSelect() && !clickedElement.closest('#showSelect')) {
      this.showSelect.set(false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    let options = [];
    const optionsStr: string = changes['options']?.currentValue;

    if (optionsStr) {
      const opt = JSON.parse(optionsStr);
      if (Array.isArray(opt)) options = opt;
    }

    this.allOptionsSignal.set(options);
    this.onChange('');
  }

  protected toggle() {
    this.showSelect.update((value) => !value);
  }

  protected onSelect(value: never, label: string) {
    const values = this.valueSignal();
    const index = values.findIndex((x) => x.value === value);

    if (index === -1) values.push({ value, label });
    else values.splice(index, 1);

    this.valueSignal.set(values);

    this.valueChange.emit([...new Set(values.map((x) => x.value))]);
  }

  protected isChecked(value: never) {
    return !!this.valueSignal().find((x) => x.value === value);
  }

  protected onChange(value: string) {
    if (!value) {
      this.optionsSignal.set(this.allOptionsSignal());
      return;
    }

    const options: Option[] = [];
    options.length = 0;

    if (this.group) {
      this.optionsSignal().forEach((x) => {
        const items: { label: string; value: never }[] = [];

        x.items?.forEach((s) => {
          if (!s.label.toLowerCase().includes(value)) return;
          items.push(s);
        });

        if (!items.length) return;

        options.push({ ...x, items });
      });
    } else {
      this.optionsSignal().forEach((x) => {
        if (!x.label.toLowerCase().includes(value)) return;
        options.push(x);
      });
    }

    this.optionsSignal.set(options);
  }
}
