import { Params } from '@angular/router';
import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const ROUTER_FEATURE_KEY = 'router';

export interface IRouterState {
  url: string;
  queryParams: Params;
  params: Params;
}

const routerFeature =
  createFeatureSelector<RouterReducerState<IRouterState>>(ROUTER_FEATURE_KEY);

export const { selectUrl } = getRouterSelectors(routerFeature);

export const selectRouteParams = createSelector(routerFeature, (state) => {
  if (!state) return {};
  return state.state.params;
});

export const selectRouteParam = <T = string>(param: string) =>
  createSelector(selectRouteParams, (params) => params[param] as T);

export const selectQueryParams = createSelector(routerFeature, (state) => {
  if (!state) return {};
  return state.state.queryParams;
});

export const selectQueryParam = <T = string>(queryParam: string) =>
  createSelector(
    selectQueryParams,
    (queryParams) => queryParams[queryParam] as T
  );

export const selectUrlWithoutQueryParams = createSelector(selectUrl, (url) =>
  !url ? url : url.split('?')[0]
);
