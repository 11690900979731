import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

const FORM_CONTROL = '.form-control';

@Directive({ selector: '[clInput]', standalone: true })
export class InputDirective implements OnDestroy, OnChanges, AfterViewInit {
  @Input() error?: string;
  private controller: AbortController = new AbortController();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private readonly zone: NgZone
  ) {}
  ngAfterViewInit(): void {
    this.addFormControl();

    this.addCharacterCount();

    this.setPlaceholder();
    this.addPasswordToggle();

    if (this.inputElement)
      this.zone.runOutsideAngular(() =>
        this.inputElement.addEventListener('input', this.listenOnValueChange, {
          signal: this.controller.signal,
        })
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['error']) this.zone.runOutsideAngular(() => this.setError());
  }

  ngOnDestroy(): void {
    this.controller.abort();
  }

  private get inputElement(): HTMLInputElement {
    return this.querySelector(FORM_CONTROL) as HTMLInputElement;
  }

  private addPasswordToggle(): void {
    if (!this.inputElement) return;

    const type = this.inputElement.getAttribute('type');

    const formGroupNode = this.inputElement.parentElement;

    if (!formGroupNode) return;

    if (type != 'password') return;

    const passwordToogleElement: HTMLSpanElement | null =
      formGroupNode.querySelector('span#passwordToggle');

    if (passwordToogleElement) {
      this.addPasswordToggleEvent(passwordToogleElement);
      return;
    }

    const spanElement: HTMLSpanElement = this.renderer.createElement('span');

    spanElement.classList.add(
      'flex',
      'flex-col',
      'cursor-pointer',
      'text-secondary'
    );
    spanElement.setAttribute('id', 'passwordToggle');
    spanElement.innerText = 'Show';

    this.addPasswordToggleEvent(spanElement);
    this.renderer.appendChild(formGroupNode, spanElement);
  }

  private querySelector(selector: string): HTMLElement | null {
    return this.el.nativeElement.querySelector(selector);
  }

  private addPasswordToggleEvent(el: HTMLSpanElement): void {
    el.addEventListener(
      'click',
      () => {
        let type: 'text' | 'password' = 'text';
        let text: 'Show' | 'Hide' = 'Hide';

        if (this.inputElement.getAttribute('type') === 'text') {
          type = 'password';
          text = 'Show';
        }

        this.inputElement.setAttribute('type', type);

        el.innerText = text;
      },
      { signal: this.controller.signal }
    );
  }

  private setPlaceholder(): void {
    if (!this.inputElement) return;

    if (
      this.inputElement.tagName === 'INPUT' ||
      this.inputElement.tagName === 'TEXTAREA'
    )
      this.inputElement.setAttribute('placeholder', '');
  }

  private setError(): void {
    const formControlGroup = this.querySelector('.form-control-group');

    if (!formControlGroup) return;

    if (this.error) {
      const formErrorElement: HTMLSpanElement =
        this.renderer.createElement('span');

      this.renderer.setAttribute(formErrorElement, 'class', 'form-error');
      formErrorElement.innerText = this.error ?? '';

      formControlGroup.classList.add('form-error');

      this.renderer.appendChild(this.el.nativeElement, formErrorElement);
    } else {
      const formErrorElement = this.querySelector('span.form-error');

      if (!formErrorElement) return;

      formControlGroup.classList.remove('form-error');

      this.renderer.removeChild(this.el.nativeElement, formErrorElement);
    }
  }

  private listenOnValueChange(e: Event): void {
    const input = e.target as HTMLTextAreaElement | null;
    if (!input) return;

    const formControlGroup: HTMLElement | null | undefined =
      input.parentElement?.parentElement;

    if (
      !formControlGroup ||
      !formControlGroup.classList.contains('form-control-group')
    )
      return;

    if (input.value) formControlGroup.classList.add('value');
    else formControlGroup.classList.remove('value');
  }

  private addFormControl(): void {
    let inputElement = this.querySelector(FORM_CONTROL);

    if (inputElement) return;

    inputElement =
      this.querySelector('input') ??
      this.querySelector('textarea') ??
      this.querySelector('select');

    if (!inputElement) return;

    inputElement.classList.add(FORM_CONTROL.replace('.', ''));
  }

  private addCharacterCount(): void {
    if (!this.inputElement) return;

    if (this.inputElement.tagName !== 'TEXTAREA') return;

    const maxlength = this.inputElement.getAttribute('maxlength');

    if (!maxlength) return;

    const i: HTMLElement = this.renderer.createElement('i');
    i.classList.add('form-count');
    i.textContent = `(max. ${maxlength} characters)`;

    const inputGroup = this.querySelector('.input-group');

    if (!inputGroup) return;

    inputGroup.appendChild(i);
  }
}
