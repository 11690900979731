import {
  BusinessSearchObject,
  CategoryObject,
} from '@citylife/dashboard/shared/utils';
import {
  GenericState,
  ICancellationPolicy,
  IOperatingHourObject,
  ISectionObject,
  IServiceCategoryObject,
  IdAndName,
} from '@citylife/shared/data-access/models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  getBusinessAction,
  getReservationSectionAction,
  getReservationServicesAction,
  setBusinessAction,
  setCategoriesAction,
  setMenuCategories,
  setReservationCancellationPolicysAction,
  setReservationHoursAction,
  setReservationSectionsAction,
  setReservationServicesAction,
} from './settings.actions';

export interface SettingsState {
  categories: GenericState<CategoryObject[]>;
  businessSearch: GenericState<BusinessSearchObject[]>;
  services: GenericState<IServiceCategoryObject[]>;
  servicesCategories: IdAndName[];
  sections: GenericState<ISectionObject[]>;
  hours: IOperatingHourObject[];
  policy: {
    cancellation: ICancellationPolicy[];
  };
}

export const initialState: SettingsState = {
  categories: {
    status: 'pending',
    data: [],
  },
  businessSearch: {
    status: 'success',
    data: [],
  },
  services: {
    status: 'pending',
    data: [],
  },
  servicesCategories: [],
  sections: {
    status: 'pending',
    data: [],
  },
  policy: {
    cancellation: [],
  },
  hours: [],
};

const reducers = createReducer(
  initialState,

  on(setCategoriesAction, (state, { value }): SettingsState => {
    return {
      ...state,
      categories: {
        data: value,
        status: 'success',
      },
    };
  }),

  on(getBusinessAction, (state): SettingsState => {
    return {
      ...state,
      businessSearch: {
        ...state.businessSearch,
        status: 'loading',
      },
    };
  }),

  on(setBusinessAction, (state, { value }): SettingsState => {
    return {
      ...state,
      businessSearch: {
        data: value,
        status: 'success',
      },
    };
  }),

  on(getReservationServicesAction, (state): SettingsState => {
    return {
      ...state,
      services: {
        ...state.services,
        status: 'loading',
      },
    };
  }),

  on(setReservationServicesAction, (state, { value }): SettingsState => {
    return {
      ...state,
      services: {
        status: 'success',
        data: value,
      },
    };
  }),

  on(getReservationSectionAction, (state): SettingsState => {
    return {
      ...state,
      sections: {
        ...state.sections,
        status: 'loading',
      },
    };
  }),

  on(setReservationSectionsAction, (state, { value }): SettingsState => {
    return {
      ...state,
      sections: {
        status: 'success',
        data: value,
      },
    };
  }),

  on(
    setReservationCancellationPolicysAction,
    (state, { value }): SettingsState => {
      return {
        ...state,
        policy: {
          cancellation: value,
        },
      };
    }
  ),

  on(setReservationHoursAction, (state, { value }): SettingsState => {
    return {
      ...state,
      hours: value,
    };
  }),

  on(setMenuCategories, (state, { value }): SettingsState => {
    return {
      ...state,
      servicesCategories: value,
    };
  })
);

export const settingsReducer = (
  state: SettingsState | undefined,
  action: Action
) => reducers(state, action);
