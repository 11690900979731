import { Injectable } from '@angular/core';

const APP_PREFIX = 'CL-';

export abstract class LocalStorage implements Storage {
  readonly length: number = 0;
  abstract clear(): void;
  abstract getItem(key: string): string | null;
  abstract key(index: number): string | null;
  abstract removeItem(key: string): void;
  abstract setItem(key: string, data: string): void;
  [key: string]: any;
  [index: number]: string;
}

@Injectable()
export class LocalStorageService {
  constructor(private storage: LocalStorage) {}

  public set(key: string, value: any): void {
    this.storage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  public get<T>(key: string): T | undefined {
    try {
      return JSON.parse(
        this.storage.getItem(`${APP_PREFIX}${key}`) as never
      ) as T;
    } catch (error) {
      return undefined;
    }
  }
  public remove(key: string): void {
    this.storage.removeItem(key);
  }

  public clear(): void {
    this.storage.clear();
  }
}
