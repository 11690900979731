import { isPlatformBrowser } from '@angular/common';
import { InjectionToken } from '@angular/core';

export const WINDOW = new InjectionToken<Window>('WindowToken');

/* Define abstract class for obtaining reference to the global window object. */
export abstract class WindowRef {
  get nativeWindow(): Window | object {
    throw new Error('Not implemented.');
  }
}

/* Define class that implements the abstract class and returns the native window object. */
export class BrowserWindowRef extends WindowRef {
  constructor() {
    super();
  }

  override get nativeWindow(): Window | object {
    return window;
  }
}

/* Create an factory function that returns the native window object. */
export function windowFactory(
  browserWindowRef: BrowserWindowRef,
  platformId: object
): Window | object {
  if (isPlatformBrowser(platformId)) return browserWindowRef.nativeWindow;

  return new Object();
}
