import { ReservationType } from '@citylife/dashboard/shared/utils';
import { GenericState } from '@citylife/shared/data-access/models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  getMerchantReservationAction,
  setAppErrorAction,
  setBankAccountsAction,
  setBanksAction,
  setBusinessDetailsAction,
  setMerchantDetailsAction,
  setMerchantReservationAction,
} from './app.actions';

export type DepositType = 'PER_GUEST' | 'FLAT';

export interface ReservationToolStateObject {
  id: string;
  name: string;
  username?: string;
  type: ReservationType;
  capacity: number;
  minimumPayment: number;
  note?: string;
  termsOfService: string;

  paymentConfirmReservation: boolean;

  acceptDeposit: boolean;
  depositAmount?: number;
  depositType?: DepositType;
}

export interface MerchantDetailsObject {
  firstname: string;
  lastname: string;
  email: string;
  phoneNumber: string;
  position: string;
  businessName?: string;
}

export interface MerchantBusinessDetailsObject {
  name: string;
  logo: string;

  email: string;
  telephone: string;
  address: string;
}

export interface AccountDetailsObject {
  id: number;
  name: string;
  accountNumber: string;
  bankName: string;
  favourite: boolean;
}

export interface BankStateObject {
  name: string;
  code: string;
}

interface AppStateObject {
  reservations: ReservationToolStateObject[];
  merchant: MerchantDetailsObject;
  business: MerchantBusinessDetailsObject;
  bankAccounts: AccountDetailsObject[];
  banks: BankStateObject[];
}

export type AppState = GenericState<AppStateObject>;

const initialState: AppState = {
  data: {
    reservations: [],
    merchant: {
      email: '',
      firstname: '',
      lastname: '',
      position: '',
      phoneNumber: '',
      businessName: '',
    },
    business: {
      name: '',
      logo: '',
      email: '',
      telephone: '',
      address: '',
    },
    bankAccounts: [],
    banks: [],
  },
  status: 'pending',
};

const reducers = createReducer(
  initialState,
  on(getMerchantReservationAction, (state): AppState => {
    return {
      status: 'loading',
      data: {
        ...state.data,
        reservations: [],
      },
    };
  }),

  on(setMerchantReservationAction, (state, { value }): AppState => {
    return {
      status: 'success',
      data: {
        ...state.data,
        reservations: value,
      },
    };
  }),

  on(setMerchantDetailsAction, (state, { value }): AppState => {
    return {
      status: 'success',
      data: {
        ...state.data,
        merchant: value,
      },
    };
  }),

  on(setBusinessDetailsAction, (state, { value }): AppState => {
    return {
      status: 'success',
      data: {
        ...state.data,
        business: value,
      },
    };
  }),

  on(setAppErrorAction, (state, { value }): AppState => {
    return {
      ...state,
      status: 'error',
      error: value,
    };
  }),

  on(setBankAccountsAction, (state, { value }): AppState => {
    return {
      ...state,
      data: {
        ...state.data,
        bankAccounts: value,
      },
    };
  }),

  on(setBanksAction, (state, { value }): AppState => {
    return {
      ...state,
      data: {
        ...state.data,
        banks: value,
      },
    };
  })
);

export const appReducer = (state: AppState | undefined, action: Action) =>
  reducers(state, action);
