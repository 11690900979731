import { Type, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { Action, ActionReducerMap, provideStore } from '@ngrx/store';

import { RouterSerializer } from './router.serializer';
import { ROUTER_FEATURE_KEY } from './router.selector';

import { buildProvider } from '../build-specific';

export const provideAppStore = (
  reducers: ActionReducerMap<unknown, Action> = {},
  effects: Type<unknown>[] = []
) => {
  return makeEnvironmentProviders([
    provideEffects(effects),
    provideRouterStore({
      serializer: RouterSerializer,
      stateKey: ROUTER_FEATURE_KEY,
    }),
    provideStore(
      {
        [ROUTER_FEATURE_KEY]: routerReducer,
        ...reducers,
      },
      {
        runtimeChecks: {
          strictActionImmutability: true,
          strictActionSerializability: true,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
          strictStateImmutability: true,
          strictStateSerializability: true,
        },
      }
    ),
    ...buildProvider,
  ]);
};
