import { Injectable } from '@angular/core';
import { Observable, tap, throwError } from 'rxjs';
import {
  CityLifeApiBaseResponse,
  CityLifeApiCalendarResponse,
  CityLifeApiFullEventDetailsResponse,
  CityLifeApiPayloadResponse,
  CityLifeApiEntrySuggestActivityRequest,
} from '../../models';

import { BackendService } from '../backend/backend.service';

@Injectable()
export class EventApiService {
  private basePath = 'events';
  constructor(private backendService: BackendService) {}

  public getEvent(
    slug: string
  ): Observable<
    CityLifeApiPayloadResponse<CityLifeApiFullEventDetailsResponse>
  > {
    if (!slug) throw throwError(() => new Error('Slug is undefined'));

    return this.backendService.get<
      CityLifeApiPayloadResponse<CityLifeApiFullEventDetailsResponse>
    >(`${this.basePath}/${slug}`);
  }

  public suggestEvent(
    body: CityLifeApiEntrySuggestActivityRequest
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post<
      CityLifeApiEntrySuggestActivityRequest,
      CityLifeApiBaseResponse
    >(`${this.basePath}/suggest`, body);
  }

  public getDetailsForCalendar(
    id: string
  ): Observable<CityLifeApiPayloadResponse<CityLifeApiCalendarResponse>> {
    return this.backendService.get<
      CityLifeApiPayloadResponse<CityLifeApiCalendarResponse>
    >(`${this.basePath}/${id}/calendar`);
  }

  // public getTopEvents(
  //   page = 1,
  //   pageSize = 6
  // ): Observable<PaginatedResponse<EventCardDetails>> {
  //   return this.backendService.get<
  //     PaginatedResponse<EventCardDetails>
  //   >(`${this.basePath}/top?page=${page}&PageSize=${pageSize}`);
  // }
}
