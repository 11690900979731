import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { ToastService } from './toast.service';

@Component({
  selector: 'cl-toast',
  standalone: true,
  templateUrl: './toast.component.html',
  imports: [NgClass, NgIf, AsyncPipe],
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
  protected data$ = inject(ToastService).data$;

  // .pipe(
  //   map((data) => ({
  //     message: data?.message,
  //     status: data?.status,
  //     align: data?.align
  //     // className: data?.align ? 'md:left-[18%] xl:left-1/5' : '',
  //   }))
  // );
}
