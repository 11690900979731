import { inject } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandlerFn } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthService } from '@citylife/shared/data-access/services';
import { CL_CONFIG } from './app-config';

export const authInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const authService = inject(AuthService);
  const clConfig = inject(CL_CONFIG);

  if (!req.url.includes(clConfig)) return next(req);

  return from(authService.getToken()).pipe(
    take(1),
    switchMap((token) =>
      next(
        !token
          ? req
          : req.clone({
              setHeaders: {
                Authorization: `Bearer ${token}`,
              },
            })
      )
    )
  );
};
