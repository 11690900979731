import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CityLifeApiPaginatedResponse,
  CityLifeApiEntrySearchResponse,
} from '../../models';
import { BackendService } from '../backend/backend.service';

@Injectable()
export class SuggestionApiService {
  private basePath = 'suggestion';
  constructor(private backendService: BackendService) {}

  public getSuggestion(
    page = 1,
    pageSize = 10
  ): Observable<CityLifeApiPaginatedResponse<CityLifeApiEntrySearchResponse>> {
    return this.backendService.get<
      CityLifeApiPaginatedResponse<CityLifeApiEntrySearchResponse>
    >(`${this.basePath}?page=${page}&pageSize=${pageSize}`);
  }
}
