import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '@citylife/shared/data-access/services';

export const emailVerifiedGuard =
  (verifyEmailRedirect: string, authorizedRedirect: string | string[]) =>
  (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const { emailVerified, loggedIn } = inject(AuthService);

    if (!loggedIn) return false;

    if (!emailVerified)
      return state.url === verifyEmailRedirect
        ? true
        : router.navigate([verifyEmailRedirect]);

    if (emailVerified && state.url === verifyEmailRedirect)
      return router.navigate([authorizedRedirect]);

    return true;
  };
