import { createAction, props } from '@ngrx/store';
import { IReservationObject, ReservationDetails } from './reservation.reducer';
import { ReservationStatus } from '@citylife/shared/data-access/models';

export const getReservationsAction = createAction(
  '[Reservation] Get Reservations',
  props<{
    status?: ReservationStatus;
    pageSize?: number;
    setLoading?: boolean;
  }>()
);

export const setReservationsAction = createAction(
  '[Reservation] Set Reservations',
  props<{ value: IReservationObject[] }>()
);

export const getReservationDetailsAction = createAction(
  '[Reservation] Get Reservation Details',
  props<{
    reservationId: number;
    setLoading: boolean;
  }>()
);

export const setReservationDetailsAction = createAction(
  '[Reservation] Set Reservation Details',
  props<{ value: ReservationDetails }>()
);

export const updateReservationStatusAction = createAction(
  '[Reservation] Update Reservation Status',
  props<{ id: number; status: ReservationStatus }>()
);

export const setReservationErrorAction = createAction(
  '[Reservation] Set Reservation Details Error',
  props<{ error: string }>()
);
