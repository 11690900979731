import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { DashboardRouterUtil } from '@citylife/dashboard/shared/utils';
import { AuthService } from '@citylife/shared/data-access/services';

export const notAuthGuard =
  (loginUrl: string | string[]) =>
  (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const router = inject(Router);
    const { loggedIn } = inject(AuthService);

    if (!loggedIn) {
      router.navigate([loginUrl], {
        queryParams: {
          [DashboardRouterUtil.Params.RedirectUri]: state.url,
        },
      });
      return false;
    }

    return true;
  };
