import { Injectable } from '@angular/core';
import {
  HttpService,
  LoggerService,
} from '@citylife/shared/data-access/services';
import { CityLifeApiBaseResponse } from '../../models';
import { Observable } from 'rxjs';

@Injectable()
export class NewsletterApiService {
  private basePath =
    'https://citylife-recurringservice.azurewebsites.net/api/newsletter';

  constructor(
    private http: HttpService,
    private loggerService: LoggerService
  ) {}

  public subscribe(email: string): Observable<CityLifeApiBaseResponse> {
    return this.http.post<{ email: string }, CityLifeApiBaseResponse>(
      this.basePath,
      {
        email,
      }
    );
  }
}
