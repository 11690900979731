import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

type LogoType = 'primary' | 'secondary' | 'alt';

const CDN_LINK = 'https://d1eufngmqy6g5j.cloudfront.net/';

const logos: { [K in LogoType]: string } = {
  primary: 'logo.svg',
  secondary: 'logo2.svg',
  alt: 'logo_alt.png',
};

@Directive({ standalone: true, selector: 'img[clAddLogo]' })
export class AddLogoDirective implements OnInit {
  @Input() type: LogoType = 'primary';
  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}
  ngOnInit(): void {
    if (this.el.nativeElement.tagName.toLowerCase() !== 'img')
      throw new Error('Directive only to be used on img tag');

    this.renderer.setAttribute(this.el.nativeElement, 'src', this.getLogo());
    this.renderer.setAttribute(this.el.nativeElement, 'alt', "CityLife's Logo");
  }

  private getLogo(): string {
    return CDN_LINK + 'svgs/logos/' + logos[this.type];
  }
}
