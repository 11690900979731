import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import {
  LoggerService,
  AuthService,
  PushNotificationService,
  PwaUpdatesService,
} from '@citylife/shared/data-access/services';
import { routes } from './dashboard-shell.route';
import { provideAppRouter, provideAppStore } from '@citylife/shared/providers';
import {
  APP_FEATURE_KEY,
  AppEffect,
  RESERVATION_FEATURE_KEY,
  ReservationEffect,
  SETTINGS_FEATURE_KEY,
  SettingsEffect,
  appReducer,
  reservationReducer,
  settingsReducer,
} from '@citylife/dashboard/shared/data-access/store';
import {
  AccountApiService,
  BankApiService,
  BusinessApiService,
  CategoryApiService,
  NotificationApiService,
  ReservationApiService,
  SearchApiService,
  authInterceptor,
} from '@citylife/shared/data-access/citylife-api';

export const provideDashboardShell = (): EnvironmentProviders => {
  return makeEnvironmentProviders([
    LoggerService,
    AuthService,
    SearchApiService,
    CategoryApiService,
    ReservationApiService,
    AccountApiService,
    BankApiService,
    BusinessApiService,
    PushNotificationService,
    PwaUpdatesService,
    NotificationApiService,
    provideHttpClient(withFetch(), withInterceptors([authInterceptor])),
    provideAppRouter(routes),
    provideAppStore(
      {
        [APP_FEATURE_KEY]: appReducer,
        [SETTINGS_FEATURE_KEY]: settingsReducer,
        [RESERVATION_FEATURE_KEY]: reservationReducer,
      },
      [SettingsEffect, AppEffect, ReservationEffect]
    ),
  ]);
};
