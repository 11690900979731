import { Injectable } from '@angular/core';

@Injectable()
export class LoggerService {
  public info(data: any): void {
    console.log(data);
  }

  public error(data: any): void {
    console.error(data);
  }
}
