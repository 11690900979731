export interface CategoryObject {
  name: string;
  subCategories: SubCategoryObject[];
}

export interface SubCategoryObject {
  value: number;
  name: string;
}

export interface BusinessSearchObject {
  id: string;
  name: string;
  address: string;
  image?: string;
}

export type ReservationType = 'RESTAURANT' | 'SERVICES';
