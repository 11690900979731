import { Pipe, PipeTransform } from '@angular/core';
import { isLoading } from '@citylife/shared/utils';

@Pipe({
  name: 'loading',
  standalone: true,
})
export class LoadingPipe implements PipeTransform {
  transform(status: string | null | undefined): boolean {
    return !status || isLoading(status as never);
  }
}
