import { createAction, props } from '@ngrx/store';
import {
  AccountDetailsObject,
  BankStateObject,
  MerchantBusinessDetailsObject,
  MerchantDetailsObject,
  ReservationToolStateObject,
} from './app.reducers';

export const getMerchantReservationAction = createAction(
  '[App] Get Merchant Reservations'
);

export const setMerchantReservationAction = createAction(
  '[App] Set Merchant Reservations',
  props<{ value: ReservationToolStateObject[] }>()
);

export const setAppErrorAction = createAction(
  '[App] Set Error',
  props<{ value: string }>()
);

export const getMerchantDetailsAction = createAction(
  '[App] Get Merchant Details'
);

export const setMerchantDetailsAction = createAction(
  '[App] Set Merchant Details',
  props<{ value: MerchantDetailsObject }>()
);

export const getBusinessDetailsAction = createAction(
  '[App] Get Merchant Business Details'
);

export const setBusinessDetailsAction = createAction(
  '[App] Set Merchant Business Details',
  props<{ value: MerchantBusinessDetailsObject }>()
);

export const getBanksAction = createAction('[App] Get Banks');
export const setBanksAction = createAction(
  '[App] Set Banks',
  props<{ value: BankStateObject[] }>()
);

export const getBankAccountsAction = createAction('[App] Get Bank Accounts');
export const setBankAccountsAction = createAction(
  '[App] Set Banks Accounts',
  props<{ value: AccountDetailsObject[] }>()
);
export const deleteBankAccountAction = createAction(
  '[App] Delete Bank Accounts',
  props<{ id: number }>()
);
export const favouriteBankAccountAction = createAction(
  '[App] Favourite Bank Accounts',
  props<{ id: number }>()
);
