import { Injectable, inject } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class PushNotificationService {
  private readonly _notifications$: Subject<unknown | null> = new ReplaySubject(
    3
  );
  private readonly push = inject(SwPush);

  public get notifications$() {
    return this._notifications$.asObservable();
  }

  public get isGranted(): boolean {
    if (!this.isSupported) return true;
    return this.permission === 'granted';
  }

  public async requestPermission(): Promise<void> {
    if (!this.isSupported) return;
    await Notification.requestPermission();
  }

  public async requestSubscription(serverPublicKey: string): Promise<
    | {
        endpoint: string;
        auth: string;
        p256dh: string;
      }
    | undefined
  > {
    const res = await this.push.requestSubscription({
      serverPublicKey,
    });

    return {
      endpoint: res.endpoint,
      ...(res.toJSON().keys as { auth: string; p256dh: string }),
    };
  }

  public subscribe() {
    if (!this.isSupported) return;
    this.push.messages.subscribe(console.log);
    this.push.notificationClicks.subscribe(({ notification }) => {
      window.open(notification.data.url);
    });
  }

  private get permission(): NotificationPermission {
    return Notification.permission;
  }

  private get isSupported() {
    return (
      'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    );
  }
}
