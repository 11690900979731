import { canActivate, loggedIn } from '@angular/fire/auth-guard';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DashboardRouterUtil } from '@citylife/dashboard/shared/utils';
import { pipe, map } from 'rxjs';

export const redirectAuthorizedTo = (
  verify: string,
  redirect: string | string[]
) =>
  canActivate(
    (activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
      pipe(
        loggedIn,
        map((loggedIn) =>
          state.url === verify
            ? true
            : !loggedIn ||
              activatedRoute.queryParams[
                DashboardRouterUtil.Params.RedirectUri
              ] ||
              redirect
        )
      )
  );
