export interface CityLifeApiEntryFeeObject {
  tier: string;
  amount: number;
  admits: number;
}

export interface CityLifeApiEntryShortBusinessDetailsObject {
  name: string;
  image: string;
  address: string;
  openingHour: string;
  closingHour: string;
  slug: string;
}

export interface CityLifeApiEntryShortEventDetailsObject {
  title: string;
  businessName: string;
  isFree: boolean;
  image: string;
  address: string;
  dateTime: string;
  time: string;
  slug: string;
  area: string;
}

export interface CityLifeApiOperatingHourObject {
  openingHour?: string;
  closingHour?: string;
  dayOfWeek: DayOfWeek;
  isClosed: boolean;
}

export type DayOfWeek =
  | 'SUNDAY'
  | 'MONDAY'
  | 'TUESDAY'
  | 'WEDNESDAY'
  | 'THURSDAY'
  | 'FRIDAY'
  | 'SATURDAY';

export interface CityLifeApiEntrySearchQuery {
  q: string[];
  tab_category?: number;
  location?: string;
  date?: string;
  budget?: string;
  exclude?: string;
}

export type ReservationType = 'RESTAURANT' | 'SERVICES';

export interface IdAndName<T = number> {
  id?: T;
  name: string;
}

export interface CancellationPolicyObject {
  hour: number;
  percentage: number;
}
export type ReservationStatusType =
  | 'CANCELLED'
  | 'PENDING'
  | 'ACCEPTED'
  | 'COMPLETED'
  | 'NO_SHOW';
export type ReservationDateStatusType =
  | 'CANCELLED'
  | 'RESCHEDULED'
  | 'ACTIVE'
  | 'PAST';

export interface OrderObject {
  name: string;
  amount: number;
}

export interface ReservationDateObject {
  startDateTime: string;
  endDateTime: string;
  status: ReservationDateStatusType;
}

export type PaymentStatus = '' | 'PENDING' | 'CANCELLED' | 'PAID' | 'REFUNDED';

export type DepositType = 'PER_GUEST' | 'FLAT';
