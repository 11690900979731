import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SettingsState } from './settings.reducers';

export const SETTINGS_FEATURE_KEY = 'settings';

const selectSettingsState =
  createFeatureSelector<SettingsState>(SETTINGS_FEATURE_KEY);

export const selectCategories = createSelector(
  selectSettingsState,
  (state) => state.categories
);

export const selectBusinessSearch = createSelector(
  selectSettingsState,
  (state) => state.businessSearch
);

export const selectReservationServices = createSelector(
  selectSettingsState,
  ({ services }) => services
);

export const selectReservationServicesCategories = createSelector(
  selectSettingsState,
  ({ servicesCategories }) => servicesCategories
);

export const selectReservationSections = createSelector(
  selectSettingsState,
  ({ sections }) => sections
);

export const selectReservationCancellationPolicy = createSelector(
  selectSettingsState,
  ({ policy }) => policy.cancellation
);

export const selectReservationHour = createSelector(
  selectSettingsState,
  ({ hours }) => hours
);
