import { FirebaseOptions } from '@angular/fire/app';
import { IEnvSpecificAppConfig } from '@citylife/dashboard/shared/app-config';

export const citylifeApiBaseUrl =
  'https://cityllife-main-server-staging.azurewebsites.net';

// export const citylifeApiBaseUrl =
// 'http://localhost:50000';

export const firebase: FirebaseOptions = {
  apiKey: 'AIzaSyB1GTRYjUl_HYzTaCdtus49WC4XZuO5slM',
  authDomain: 'explorecitylify.firebaseapp.com',
  databaseURL: 'https://explorecitylify-default-rtdb.firebaseio.com',
  projectId: 'explorecitylify',
  storageBucket: 'explorecitylify.appspot.com',
  messagingSenderId: '213245304061',
  appId: '1:213245304061:web:958a9a4bb958c44b8e7294',
};

export const appConfig: IEnvSpecificAppConfig = {
  production: false,
  analyticKey: '',
};
