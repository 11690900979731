import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BackendService } from '../backend/backend.service';

import {
  CityLifeApiBaseResponse,
  CityLifeApiOperatingHourResponse,
  CityLifeApiBusinessInfoResponse,
  CityLifeApiBusinessPhotoResponse,
  CityLifeApiPayloadResponse,
  CityLifeApiAddBusinessRequest,
  CityLifeApiMerchantBusinessDetailsResponse,
} from '../../models';

@Injectable()
export class BusinessApiService {
  private basePath = 'businesses';
  constructor(private backendService: BackendService) {}

  public getPhotos(
    id: string,
    pageSize = 3
  ): Observable<
    CityLifeApiPayloadResponse<CityLifeApiBusinessPhotoResponse[]>
  > {
    return this.backendService.get<
      CityLifeApiPayloadResponse<CityLifeApiBusinessPhotoResponse[]>
    >(`${this.basePath}/${id}/photo?size=${pageSize}`);
  }

  public getBusinesssInformation(
    slug: string
  ): Observable<CityLifeApiPayloadResponse<CityLifeApiBusinessInfoResponse>> {
    return this.backendService.get<
      CityLifeApiPayloadResponse<CityLifeApiBusinessInfoResponse>
    >(`${this.basePath}/${slug}`);
  }

  public getBusinessFatures(
    id: string
  ): Observable<CityLifeApiPayloadResponse<string[]>> {
    return this.backendService.get<CityLifeApiPayloadResponse<string[]>>(
      `${this.basePath}/${id}/feature`
    );
  }

  public getPromos(
    id: string
  ): Observable<CityLifeApiPayloadResponse<string[]>> {
    return this.backendService.get<CityLifeApiPayloadResponse<string[]>>(
      `${this.basePath}/${id}/promo`
    );
  }

  public getBusinessHours(
    id: string
  ): Observable<
    CityLifeApiPayloadResponse<CityLifeApiOperatingHourResponse[]>
  > {
    return this.backendService.get<
      CityLifeApiPayloadResponse<CityLifeApiOperatingHourResponse[]>
    >(`${this.basePath}/${id}/hour`);
  }

  public addOrEditBusiness(
    body: CityLifeApiAddBusinessRequest
  ): Observable<CityLifeApiBaseResponse> {
    const formData = new FormData();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    for (const key in body) formData.append(key, body[key]);
    return this.backendService.post(this.basePath, formData);
  }

  public getMerchantBusinessDetails(): Observable<
    CityLifeApiPayloadResponse<CityLifeApiMerchantBusinessDetailsResponse>
  > {
    return this.backendService.get(this.basePath);
  }
}
