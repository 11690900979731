import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';

import { APP_CONFIG } from '@citylife/dashboard/shared/app-config';
import { CL_CONFIG } from '@citylife/shared/data-access/citylife-api';
import { LocalStorage } from '@citylife/shared/data-access/services';
import { localStorageFactory } from '@citylife/shared/utils';
import { provideDashboardShell } from '@citylife/dashboard/shell';

import { environment, citylifeApiBaseUrl, firebase } from '../environments';
import { provideAppAnalytics } from '@citylife/shared/providers';
import { ToastService } from '@citylife/shared/ui/toast';

import { provideClientHydration } from '@angular/platform-browser';
import { provideServiceWorker } from '@angular/service-worker';
import { provideAnimations } from '@angular/platform-browser/animations';

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideAnimations(),
    {
      provide: APP_CONFIG,
      useValue: environment,
    },
    {
      provide: CL_CONFIG,
      useValue: citylifeApiBaseUrl,
    },
    { provide: LocalStorage, useFactory: localStorageFactory },
    importProvidersFrom([
      provideFirebaseApp(() => initializeApp(firebase)),
      provideAuth(() => getAuth()),
    ]),
    ToastService,
    provideDashboardShell(),
    provideAppAnalytics(environment.analyticKey),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
