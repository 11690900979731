import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  getReservationDetailsAction,
  getReservationsAction,
  setReservationDetailsAction,
  setReservationErrorAction,
  setReservationsAction,
  updateReservationStatusAction,
} from './reservation.action';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { selectReservations } from './reservation.selectors';
import {
  CityLifeApiErrorResponse,
  ReservationApiService,
} from '@citylife/shared/data-access/citylife-api';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ReservationEffect {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly reservationApi: ReservationApiService
  ) {}

  private getReservations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getReservationsAction),
      withLatestFrom(this.store.select(selectReservations)),
      map(([{ pageSize, status }, { data }]) => ({
        pageSize: pageSize ?? 10,
        status: status ?? 'ACCEPTED',
        lastIndex: data.length > 0 ? data[data.length - 1].id : 0,
      })),
      switchMap(({ pageSize, lastIndex, status }) =>
        this.reservationApi
          .getReservations(lastIndex, pageSize, status)
          .pipe(map(({ data }) => setReservationsAction({ value: data })))
      )
    )
  );
  private getReservationsDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getReservationDetailsAction),
      withLatestFrom(this.store.select(selectReservations)),
      switchMap(([{ reservationId }]) =>
        this.reservationApi.getReservationDetails(reservationId).pipe(
          map(({ data }) => setReservationDetailsAction({ value: data })),
          catchError((err: HttpErrorResponse) =>
            of(
              setReservationErrorAction({
                error: (err.error as CityLifeApiErrorResponse).message,
              })
            )
          )
        )
      )
    )
  );

  private updateReservationStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateReservationStatusAction),
      switchMap(({ id, status }) =>
        this.reservationApi.updateReservationStatus(id, status).pipe(
          switchMap(() =>
            of(
              getReservationsAction({}),
              getReservationDetailsAction({
                reservationId: id,
                setLoading: false,
              })
            )
          )
        )
      )
    )
  );
}
