import { Injectable } from '@angular/core';
import * as confetti from 'canvas-confetti';

@Injectable({ providedIn: 'root' })
export class ConfettiService {
  private numberOfConfetti = 3;
  private random(min: number, max: number) {
    return Math.round(Math.random() * (max - min) + min);
  }
  public showRandom(): void {
    switch (this.random(1, this.numberOfConfetti)) {
      case 1:
        this.circle();
        break;
      case 2:
        this.threePop();
        break;
      case 3:
        this.drop();
        break;
      default:
        this.circle();
        break;
    }
  }

  private threePop() {
    const _confetti = confetti.create(undefined, {
      resize: true, // will fit all screen sizes
    });
    const origin = {
      y: 0.7,
      x: 0.5,
    };
    const startVelocity = 60;
    const gravity = 1;

    _confetti({
      angle: 145,
      spread: 25,
      particleCount: this.random(50, 100),
      origin,
      startVelocity,
      gravity,
    });

    setTimeout(
      () =>
        _confetti({
          angle: 35,
          spread: 25,
          particleCount: this.random(50, 100),
          origin,
          startVelocity: startVelocity + 20,
          gravity,
        }),
      500
    );
    setTimeout(
      () =>
        _confetti({
          angle: 90,
          spread: 35,
          particleCount: this.random(100, 300),
          origin,
          startVelocity: startVelocity + 50,
          gravity,
        }),
      700
    );
  }

  private circle() {
    const _confetti = confetti.create(undefined, {
      resize: true, // will fit all screen sizes
    });
    const origin = {
      y: 0.5,
      x: 0.5,
    };

    _confetti({
      spread: 360,
      particleCount: this.random(600, 1000),
      origin,
      startVelocity: 60,
    });
  }

  private drop() {
    const _confetti = confetti.create(undefined, {
      resize: true, // will fit all screen sizes
    });
    const origin = {
      y: 0,
      x: 0.5,
    };

    _confetti({
      spread: -160,
      angle: -90,
      particleCount: this.random(600, 1000),
      origin,
      gravity: 0.4,
    });
  }
}
