import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class PwaUpdatesService {
  constructor(private update: SwUpdate) {}

  public checkUpdate(): void {
    if (!this.update.isEnabled) return;

    this.update.versionUpdates.subscribe((event) => {
      if (event.type === 'VERSION_READY') {
        location.reload();
      }
    });
  }
}
