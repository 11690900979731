import { makeEnvironmentProviders } from '@angular/core';
import {
  Routes,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';

export const provideAppRouter = (routes: Routes) => {
  return makeEnvironmentProviders([
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
      }),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      })
    ),
  ]);
};
