import { Injectable } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import { Observable } from 'rxjs';
import {
  CityLifeApiBaseResponse,
  CityLifeApiPayloadResponse,
  CitylifeApiAddNotificationRequest,
  CitylifeApiNotificationServerKeyResponse,
} from '../../models';

@Injectable()
export class NotificationApiService {
  private basePath = 'notifications';
  constructor(private backendService: BackendService) {}

  public addToken(
    body: CitylifeApiAddNotificationRequest
  ): Observable<CityLifeApiBaseResponse> {
    return this.backendService.post(`${this.basePath}/add`, body);
  }

  public getServerPublicKey(): Observable<
    CityLifeApiPayloadResponse<CitylifeApiNotificationServerKeyResponse>
  > {
    return this.backendService.get(`${this.basePath}`);
  }
}
