import {
  AsyncPipe,
  DOCUMENT,
  NgClass,
  NgTemplateOutlet,
} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  effect,
  signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  Router,
  RouterLinkActive,
  RouterLinkWithHref,
  RouterOutlet,
} from '@angular/router';
import {
  getMerchantReservationAction,
  selectAppLoadingStatus,
} from '@citylife/dashboard/shared/data-access/store';
import { DashboardRouterUtil } from '@citylife/dashboard/shared/utils';
import { AuthService } from '@citylife/shared/data-access/services';
import { AddLogoDirective } from '@citylife/shared/directives/add-logo';
import { LoadingPipe } from '@citylife/shared/pipes/loading';
import { Store } from '@ngrx/store';
import { MerchantAddReservationComponent } from '@citylife/dashboard/shell/ui/merchant-add-reservation';

@Component({
  selector: 'cl-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    MatIconModule,
    AddLogoDirective,
    NgTemplateOutlet,
    NgClass,
    RouterLinkWithHref,
    RouterLinkActive,
    AsyncPipe,
    LoadingPipe,
    MerchantAddReservationComponent,
  ],
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardLayoutComponent {
  protected addReservationModal = false;
  protected loading$ = this.store.select(selectAppLoadingStatus);
  protected displayNav = signal(false);

  protected usernameDropdown = false;

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly store: Store,
    @Inject(DOCUMENT) private readonly document: Document
  ) {
    this.store.dispatch(getMerchantReservationAction());

    effect(() => {
      if (this.displayNav())
        this.document.body.classList.add('overflow-y-hidden');
      else this.document.body.classList.remove('overflow-y-hidden');
    });
  }

  @HostListener('document:click', ['$event'])
  private onDocumentClick(event: Event): void {
    if (!this.displayNav() && !this.usernameDropdown) {
      return;
    }

    const clickedElement = event.target as HTMLElement;

    if (
      this.displayNav() &&
      !clickedElement.closest('#navbar') &&
      !clickedElement.closest('#navopen')
    ) {
      this.closeNav();
    }

    if (this.usernameDropdown && !clickedElement.closest('#signOut')) {
      this.usernameDropdown = false;
    }
  }

  protected cancel() {
    this.addReservationModal = false;
    document.body.style.overflow = 'auto';
  }

  protected openModal() {
    this.addReservationModal = true;
    this.closeNav();
    document.body.style.overflow = 'hidden';
  }

  protected navItems = [
    {
      title: 'Home',
      route: DashboardRouterUtil.Routes.Home.Root,
      icon: 'home',
    },
    {
      title: 'Tools',
      route: DashboardRouterUtil.Routes.Tool.Root,
      icon: 'person_add',
    },
    {
      title: 'Reservations',
      route: DashboardRouterUtil.Routes.Reservation.Root,
      icon: 'event_seat',
    },
    {
      title: 'Reviews',
      route: 'review',
      icon: 'reviews',
    },
    {
      title: 'Settings',
      route: DashboardRouterUtil.Routes.Settings.Root,
      icon: 'settings',
    },
  ];

  protected get fullname(): string {
    return this.authService.name;
  }

  protected get abbr(): string {
    const allNames = this.fullname.trim().split(' ');
    const initials = allNames.reduce((acc, curr, index) => {
      if (index === 0 || index === allNames.length - 1) {
        acc = `${acc}${curr.charAt(0).toUpperCase()}`;
      }
      return acc;
    }, '');
    return initials;
  }

  protected async signOut() {
    await this.authService.signOut();
    this.router.navigateByUrl('/auth');
  }

  protected openNav() {
    this.displayNav.set(true);
    document.body.style.overflow = 'hidden';
  }

  protected closeNav() {
    this.displayNav.set(false);
    document.body.style.overflow = 'auto';
  }

  protected openDropdown() {
    this.usernameDropdown = !this.usernameDropdown;
  }
}
