import { Injectable, inject } from '@angular/core';
import { BackendService } from '../backend/backend.service';
import {
  CityLifeApiAddBankAccountRequest,
  CityLifeApiBankAccountResponse,
  CityLifeApiBanksResponse,
  CityLifeApiBaseResponse,
  CityLifeApiPayloadResponse,
  CityLifeApiVerifyBankAccountRequest,
  CityLifeApiVerifyBankAccountResponse,
} from '../../models';

@Injectable()
export class BankApiService {
  private basePath = 'banks';
  private backendService = inject(BackendService);

  public getBankAccounts() {
    return this.backendService.get<
      CityLifeApiPayloadResponse<CityLifeApiBankAccountResponse[]>
    >(`${this.basePath}/account`);
  }

  public deleteBankAccountBank(id: number) {
    return this.backendService.delete<
      CityLifeApiPayloadResponse<CityLifeApiBanksResponse[]>
    >(`${this.basePath}/account/${id}`);
  }

  public favouriteBankAccountBank(id: number) {
    return this.backendService.get<
      CityLifeApiPayloadResponse<CityLifeApiBanksResponse[]>
    >(`${this.basePath}/account/${id}/favourite`);
  }

  public getBanks() {
    return this.backendService.get<
      CityLifeApiPayloadResponse<CityLifeApiBanksResponse[]>
    >(`${this.basePath}`);
  }

  public verifyBankAccount(body: CityLifeApiVerifyBankAccountRequest) {
    return this.backendService.post<
      CityLifeApiVerifyBankAccountRequest,
      CityLifeApiPayloadResponse<CityLifeApiVerifyBankAccountResponse>
    >(`${this.basePath}/account/verify`, body);
  }

  public addBankAccount(body: CityLifeApiAddBankAccountRequest) {
    return this.backendService.post<
      CityLifeApiAddBankAccountRequest,
      CityLifeApiBaseResponse
    >(`${this.basePath}/account/add`, body);
  }
}
