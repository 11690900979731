import { InjectionToken } from '@angular/core';

export interface IAppConfig extends IEnvSpecificAppConfig {
  staticFilesCdnRoute: string;
}

export interface IEnvSpecificAppConfig {
  production: boolean;

  analyticKey: string;
}

export const APP_CONFIG = new InjectionToken<IAppConfig>('app.config');
