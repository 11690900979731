import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CityLifeApiPrimaryCategoryResponse,
  CityLifeApiPaginatedResponse,
  CityLifeApiPayloadResponse,
  CityLifeApiCategoryResponse,
  CityLifeApiFullCategoryResponse,
} from '../../models';
import { BackendService } from '../backend/backend.service';

@Injectable()
export class CategoryApiService {
  private basePath = 'categories';
  constructor(private backendService: BackendService) {}

  public getAllCategories(): Observable<
    CityLifeApiPayloadResponse<CityLifeApiFullCategoryResponse[]>
  > {
    return this.backendService.get<
      CityLifeApiPayloadResponse<CityLifeApiFullCategoryResponse[]>
    >(this.basePath);
  }

  public getPrimaryAvailableCategories(): Observable<
    CityLifeApiPayloadResponse<CityLifeApiPrimaryCategoryResponse[]>
  > {
    return this.backendService.get<
      CityLifeApiPayloadResponse<CityLifeApiPrimaryCategoryResponse[]>
    >(`${this.basePath}/available`);
  }

  public getAvailableSubCategories(
    primaryCategoryId: number
  ): Observable<CityLifeApiPayloadResponse<string[]>> {
    return this.backendService.get<CityLifeApiPayloadResponse<string[]>>(
      `${this.basePath}/available/${primaryCategoryId}`
    );
  }

  public getActivityCategories(
    activityId: string
  ): Observable<CityLifeApiPayloadResponse<string[]>> {
    return this.backendService.get<CityLifeApiPayloadResponse<string[]>>(
      `${this.basePath}/activity/${activityId}`
    );
  }

  public getTopCategories(
    page = 1,
    pageSize = 5
  ): Observable<CityLifeApiPaginatedResponse<CityLifeApiCategoryResponse>> {
    return this.backendService.get<
      CityLifeApiPaginatedResponse<CityLifeApiCategoryResponse>
    >(`${this.basePath}/top?page=${page}&pageSize=${pageSize}`);
  }
}
